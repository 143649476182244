import styled from 'styled-components/macro'
import { BREAK_ } from 'constants'
import { motion } from 'framer-motion'

export const Main = styled(motion.div)`
  max-width: 100vw;
  width: 424px;
  background-color: #ffffff;
  padding: 20px 40px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  top:59px;
  left:50%;
  position: fixed;
  border: 1px solid rgba(0, 0, 0, 0.1);
  ${ BREAK_.md_up } {
    top:0;
    left:calc(50% + 50px);
  }
`
export const Option = styled.div`
  display: flex;
  cursor: pointer;
  width: 245px;
  font-size: 1.0625rem;
  background: ${({ active }) => (active ? '#E6E6E6' : 'transparent')};
  justify-content: center;
`
export const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px;
  flex: 1;
`
export const Text = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  font-weight: 600;
  width: 100%;
  color: ${({ clr }) => clr.primary_text};
  justify-content: center;
`
