import { atom } from 'jotai'

export const _bootstrap = atom({
  siteid      : null,
  sitename    : null,
  favicon     : null,
  type        : null,
  projectids  : null,
  projectnames: [],
  cdn         : null,
  routes      : null,
  startpage   : null,
  dropdown    : [],
  openhouse   : null,
  moveinprice : null,
})