import styled from 'styled-components/macro'

export const Center = ({ point }) => {
  return (
    <>
      <StyledCenter point={point} id='center'/>
    </>
  )
}

const StyledCenter = styled.div`
  position: absolute;
  left: ${ ({point}) => point?.DESKTOP?.[0] ?? '' }px;
  top: ${ ({point}) => point?.DESKTOP?.[1] ?? '' }px;
  color: yellow;
  background: yellow;
  width: 0px;
  height: 0px;
  border-radius:150%;
`