import styled     from 'styled-components/macro'
import { BREAK_ } from 'constants'


export const Label = styled.div`
  font-size  : 20px;
  text-align : center;
  font-weight: 700;
  padding    : 0 36px;
  ${BREAK_.md_dn} {
    font-size: 18px;
    padding  : 0 15px;
  }
    /* border: 1px solid black; */
`

export const Main = styled.div`
  display : flex;
  position: absolute;
  bottom  : 120px;
  ${BREAK_.md_dn} {
    bottom: 60px;
  }
  left           : 0;
  align-items    : center;
  width          : 100%;
  justify-content: center;
  background     : transparent;
    /* border: 10px solid black; */
`