import { useState }                 from 'react'
import styled                       from 'styled-components/macro'
import { Swiper, SwiperSlide }      from 'swiper/react'
import { Pagination, Keyboard }     from 'swiper'
import { SwiperStyle, SliderStyle } from './MaximizedGallery.Swiper.style'
import { SwiperNav }                from './MaximizedGallery.Navigation'
import { CatchSlide }               from './MaximizedGallery.catch'

export const Slider = ({initial, images}) => {

  const [ activeIndex, setActiveIndex ] = useState(null)

  const swiper = {
    modules       : [Pagination, Keyboard],
    slidesPerView : 1,
    spaceBetween  : 20,
    initialSlide  : initial ?? 0,
    pagination    : { dynamicBullets: true },
    keyboard      : { enabled: true },
    onSlideChange : e => { setActiveIndex( e.activeIndex ) }
  }

  return (
    <StyleInjection>
      <Swiper {...swiper}>
        <SwiperNav activeIndex={activeIndex} {...{images}}/>
        { 
          images?.map((img, i) => (
            <SwiperSlide key={i}>
              <Img src={img.url} alt=''/>
              <CatchSlide/>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </StyleInjection>
  )
}

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
const StyleInjection = styled.div`
  /* ---------------------------------- */
  /*  */ height:100%;  /* <--- Very important  */
  /* ---------------------------------- */
  ${ SwiperStyle }
  ${ SliderStyle }
`