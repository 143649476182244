import { Boolean }          from "./Filter.Controls.Boolean";
import { Interval }         from "./Filter.Controls.Interval";
import { MultiSelect }      from "./Filter.Controls.MultiSelect";
import { Dropdown }         from "./Filter.Controls.Dropdown";
import { Col, Row }         from "./Filter.Controls.style";
import { Icon }             from "assets/Icons_";
import { _project }         from "state/store.projects";
import { getFocus, useGet } from "state/jotai";
import { active_project }   from "state/store.global";
import { getProjectData } from "state/jotai.projectDataHooks";
    // import { getProjectData }   from "state/jotai.projectDataHooks";

  const dropdownData = {
    Type   : "Dropdown",
    Default: [0],
  };

  const houseDropdownData = {
    Type   : "Dropdown",
    Default: [0],
  };

export const Controls = ({ active, options, setFilters }) => {
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const iconLookup   = getFocus(_project, "IconLookup")?.[pin]?.[pid] ?? [];
  const filterType   = getFocus(_project, "filtertypes")?.[pin]?.[pid] ?? [];

  const filter = filterType.map((ft, i)=> {
   return {
    Type          : "Dropdown",
    Label         : ft.label,
    labelProperty  : ft.labelProperty,
    Default       : [0],
    PossibleValues: getProjectData(ft.atomKey).map((i, j) => ({
      ID   : j,
      Value: !j
        ? buttonsLabel?.filter((btn) => btn?.UILabelProperty === ft.labelProperty)[0]
            ?.Label :  i,
      ValueProperty: ft.propertyKey,
    })),
    ValueProperty: ft.propertyKey,
   }
  })
  
  let lookup = {};

      // const lookup = {
      //   'Husleje'       : 'price',
      //   'Månadshyra'    : 'price',
      //   'Størrelse'     : 'area',
      //   'Storlek'       : 'area',
      //   'Etage'         : 'floor',
      //   'Våning'        : 'floor',
      //   'Status'        : 'status',
      //   'Altantype'     : 'balcony',
      //   'Antal værelser': 'rooms',
      //   'Værelser'      : 'rooms',
      //   'Orientering'   : 'orientation',
      //   'Boligtype'     : 'boligtype',
      //   'Rent per month': 'price',
      //   'Size'          : 'area',
      //   'Floor'         : 'floor',
      // }

    // const PossibleValues = getProjectData("unitcategories").map((i, j) => ({
    //   ID   : j,
    //   Value: !j
    //     ? buttonsLabel?.filter((btn) => btn?.UILabelProperty === "AlleBoligtyper")[0]
    //         ?.Label
    //                :  i,
    //   ValueProperty: "Boligtype",
    // }));

    // const PossibleHouseValues = getProjectData("housecategories").map((i, j) => ({
    //   ID   : j,
    //   Value: !j
    //     ? buttonsLabel?.filter((btn) => btn?.UILabelProperty === "AlleBoligtyper")[0]
    //         ?.Label
    //                :  i,
    //   ValueProperty: "Husnrtype",
    // }));
    
    iconLookup?.forEach((icon) => {
      lookup[icon?.Property] = icon?.Label;
    });

    // Not needed for Terrassehaverne project
    // dropdownData.PossibleValues      = PossibleValues;
    // houseDropdownData.PossibleValues = PossibleHouseValues;

    // dropdownData.Label = buttonsLabel?.filter(
    //   (btn) => btn?.UILabelProperty === "Boligtype"
    // )[0]?.Label;

    // houseDropdownData.Label = "Husnrfilter";

    if (!options.filter((i) => i.Type == "Dropdown").length > 0) {
      // options.push(dropdownData);
      // options.push(houseDropdownData);
      options.push(...filter)
    }
    
  return (
    <>
      {options.map(({ Type, Label, ValueProperty, labelProperty, ...i }, j) => {
        const props = { ...i, active, setFilters, key: j, index: j, Label };
        const icon  = { icon: lookup[Label], color: clr?.icons, size: 40 };
        return (
          <Col key = {j}>
              <div
              style={{
                border       : "0px solid black",
                display      : "flex",
                flexDirection: "column",
                alignItems   : "center",
                height       : 65,
              }}
            >
              <Icon {...icon} />
              <div style = {{ flex: "0 0 5px" }} />
              <div style = {{ position: "relative", top: -7, color: '#fff' }}>{Label}</div>
              </div>
            <Row>
              {Type === "MultiSelect" && <MultiSelect {...props} />}
              {Type === "MultiRange" && <Interval {...props} />}
              {Type === "Boolean"    && <Boolean {...props} />}
              {Type === "Dropdown"   && <Dropdown {...props} filterName={ValueProperty} labelName={labelProperty} />}
            </Row>
          </Col>
        );
      })}
    </>
  );
};
