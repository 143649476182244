import styled from 'styled-components/macro'
import { motion } from 'framer-motion'

const animation = {
  initial   : { opacity:0 },
  animate   : { opacity:1 },
  exit      : { opacity:0 },
  transition: { duration:0.2, ease:'linear' }
}

export const Backdrop = ({onClick}) => {

  return <StyledBackdrop 
    {...animation}
    onClick={onClick}  
  />

} 

export const StyledBackdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #00000095;
`