import styled, { css } from 'styled-components/macro'
import { sb }          from 'styles/styles.scrollbar'
import { BREAK_ }      from 'constants'

export const Bottom = styled.div`
  width           : 100%;
  display         : flex;
  justify-content : center;
  align-items     : center;
  gap             : 10px;
  padding         : 30px 0;
  position        : sticky;
  bottom          : 0;
  background-color: ${({bgColor}) => bgColor };
`
export const Head = styled.div`
  display        : flex;
  justify-content: flex-end;
  align-items    : center;
  padding        : 10px;
  align-self     : flex-end;
  position       : absolute;
  z-index        : 1000;
  top            : 11px;
  right          : 3px;
  ${ BREAK_.md_dn } {
    top  : 20px;
    right: 10px;
  }
`
export const Scroll = styled.div`
    // overflow-y: scroll;
    // overflow-x: hidden;
  width           : 100%;
  background-color: ${({clr}) => clr?.primary };
  ${ sb.hide };
  font-size: 1rem;
    /* border:2px solid black; */
  position: relative;
  z-index : 0;
`

export const Count = styled.div`
  color     : ${({clr}) => clr?.primary_text};
  width     : 100%;
  text-align: center;
`