import { Button, Stack } from "@mui/material";
import { useContactDrawer } from "components/Contact/useContactDrawer";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toggle_drawer } from "state/actions";
import { getFocus, useAtom, useGet } from "state/jotai";
import Unit from "./Unit.model";
import { active_project, kioskMode, previousUrl } from "state/store.global";
import { _project } from "state/store.projects";

function DrawerCTA({ unit }) {
  const { open } = useContactDrawer();
  const KioskMode = useGet(kioskMode);

  const navigateTo = useNavigate();
  const [, setPreviousUrl] = useAtom(previousUrl);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const {
    i18n: { language },
  } = useTranslation();
  const [, toggleDrawer] = useAtom(toggle_drawer);

  const handleMore = () => {
    setPreviousUrl(location.pathname);
    navigateTo(`${language}/unit/${unit.getId()}`);
    toggleDrawer();
  };

  const handleContact = () => {
    open(unit);
  };

  return (
    <Stack direction="row" spacing="1px" justifyContent="center">
      {KioskMode ? (
        <Button variant="contained" size="large" onClick={handleMore}>
          {
            buttonsLabel?.filter(btn => btn.UILabelProperty === "LæsMere")[0]
              ?.Label
          }
        </Button>
      ) : (
        <>
          <Button variant="contained" size="large" onClick={handleContact}>
            {
              buttonsLabel?.filter(btn => btn.UILabelProperty === "Kontakt")[0]
                ?.Label
            }
          </Button>
          <Button variant="contained" size="large" onClick={handleMore}>
            {
              buttonsLabel?.filter(btn => btn.UILabelProperty === "LæsMere")[0]
                ?.Label
            }
          </Button>
        </>
      )}
    </Stack>
  );
}

DrawerCTA.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default DrawerCTA;
