import { useMousetrap } from "use-mousetrap";
import { useNavigate } from "react-router-dom";
import { useAtom } from "state/jotai";
import { toggle_drawer } from "state/actions";
import { close_drawer } from "state/actions";
import { vis_compare, kioskMode } from "state/store.global";
import { vis_desktop_nav } from "state/store.global";
import { getFocus, useSet, useGet } from "state/jotai";
import { useEffect } from "react";
import { useState } from "react";

export const Hotkeys = ({ kiosk }) => {
  const toggleDrawer = useAtom(toggle_drawer)[1];
  const closeDrawers = useAtom(close_drawer)[1];
  const setComp = useAtom(vis_compare)[1];
  const setNav = useAtom(vis_desktop_nav)[1];
  const navigateTo = useNavigate();
  const getKioskMode = useGet(kioskMode);
  const currentLocation = window.location.href;

  // useMousetrap('q q q', () => alert('q q q') )
  // useMousetrap('a a a', () => alert('a a a') )

  // useMousetrap('1',() => toggleDrawer('filter'))
  // useMousetrap('2',() => toggleDrawer('sorter'))
  // useMousetrap('3',() => toggleDrawer('detail'))
  // useMousetrap('0',() => setNav(a => !a))

  // useMousetrap('x',() => navigateTo('/en/list'))
  // useMousetrap('c',() => navigateTo('/en/isometry'))
  // useMousetrap('v',() => navigateTo('/en/compare'))
  // useMousetrap('b',() => navigateTo('/en/gallery'))
  // useMousetrap('n',() => navigateTo('/en/downloads'))
  // useMousetrap('m',() => navigateTo('/en/airphoto'))
  // useMousetrap('å',() => navigateTo('/en/frontpage'))
  // useMousetrap('u',() => navigateTo('/en/unit'))
  function toggleFullscreen() {
    const isFullscreen =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement;

    if (isFullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    }
  }

  useMousetrap("l", () => {
    if (currentLocation.includes("kiosk")) {
      toggleFullscreen();
      //navigateTo("/da/isometry?mode=kiosk");
    }
  });
  useMousetrap("esc", () => {
    closeDrawers();
    setComp(false);
  });

  return null;
};
