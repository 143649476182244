import styled            from 'styled-components/macro'
import { BREAK_ }        from 'constants'
import { Icon }          from 'assets/Icons_'
import { useScalePoint } from 'hooks/useScalePoint' 
import { useBreak }      from 'hooks/useBreak'

export const Recenter = ({z,bounds,dim}) => {

  const isMobile = useBreak('md_dn')
  const scale    = useScalePoint(bounds,dim,true) + ( isMobile ? 0.1 : 0 )

  const click = () => {
    z.current?.zoomToElement('center',scale,200,'easeOut')
  }

  return (
    <Wrap onClick = {click}>
    <Icon icon    = 'readjust' size = '30' color = {'#000'}/>
    </Wrap>
  )

}

const Wrap = styled.div`
  position: absolute;
  top     : calc(90px - 59px);
  ${BREAK_.md_dn} { 
    top : calc(130px - 59px);
    left: 20px;
  }
  left       : 30px;
  display    : flex;
  align-items: center;
  gap        : 6px;
  font-size  : 14px;
  width      : fit-content;
  cursor     : pointer;
  ${BREAK_.md_dn} { font-size: 0.6875rem; }
  line-height: 1;
    /* border: 1px solid black; */
`