import { useState }                from 'react'
import { getFocus, useAtom, useGet }                 from 'state/jotai'
import { active_project, kioskMode, share_content }           from 'state/store.global'
import { vis_share }               from 'state/store.global'
import { Backdrop }                from 'styles/styles.backdrop'
import { STATICS, EMAIL }                 from 'api/api'
import { Icon }                    from 'assets/Icons_'
import { Button, Stack , ClickAwayListener , Tooltip }           from '@mui/material'
import { Container, Inner, Input } from './Share.Portal.style'
import { useTranslation }          from 'react-i18next'
import { isMobile }                from 'react-device-detect'
import { SnackbarProvider, useSnackbar } from 'notistack';

import { FacebookShareButton, FacebookMessengerShareButton } from 'react-share'
import { _project } from 'state/store.projects'
import { axios_live } from 'utilities/axios.instances'

const data = {
  Header1: 'Del bolig',
  Text1  : 'Forestiller du dig at denne bolig er det perfekte match til en person i dit netværk? Del den med det samme.',
  Header2: 'Indtast email',
}

export const SharePortal = ({}) => {

  const content             = useAtom(share_content)
  const setShare            = useAtom(vis_share)[1]
  const { i18n:{language} } = useTranslation()
  const [email, setEmail]   = useState("")
  const [shareTooltip, setShareTooltip] = useState(false);

  let pattern = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/ ;

  const { enqueueSnackbar } = useSnackbar();
  
  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
 
  const menus        = getFocus(_project, "menuItems")?.[pin]?.[pid] ?? [];

  const KioskMode = useGet(kioskMode);

  const close   = { icon: 'close6', color: '#fff', wrap: { position: 'absolute', top: 20, right: 20 }, size: '20', click: () => setShare(false), }
  const homeUrl = window.location.origin;
  const unitUrl = `${homeUrl}/${language}/unit/${content[0]}`;

  let clearTooltip;  
  const inputChange    = (e) => { setEmail(e.target.value) }
  
  const handleDelBolig = () => {
    let success = 'success'
    let file = `${STATICS}email_logo.png`;
    let base64String;
    if(pattern.test( email )) {
      fetch(file)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Image not found or cannot be accessed.');
        }
        return response.blob();
      })
      .then((blob) => {
        const reader = new FileReader();
  
        reader.onload = function () {
          base64String = reader.result.split(',')[1];  // Extract the base64 part
          
          let data = {
            "recipient_email": email,
            "eido_url"       : homeUrl,
            "cta_url"        : unitUrl,
            "external_url"   : menus[0]?.URL,
            "logo_base64"    : `data:image/png;base64,${base64String}`
          }
  
          if(base64String) {
            axios_live.post(EMAIL(), data)
            .then( res => {
              console.log("🚀 ~ file: Share.Portal.jsx:79 ~ .then ~ res:", res)
              // let variant = res.data.hasOwnProperty("recipient_email")
              let variant = Object.hasOwnProperty.call(res.data, "recipient_email") ? 1 : 0;
              console.log("🚀 ~ file: Share.Portal.jsx:82 ~ .then ~ variant:", variant)

              enqueueSnackbar( variant ? "Emailen er blevet sendt" : "E-mailen er ugyldig", { variant: variant ? 'success' : 'error' });
            })
            .catch( err => console.log(err) )
          }
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        alert(error.message);
      });
    }else {
      enqueueSnackbar( "E-mailen er ugyldig", { variant: 'error' });
    }

      // console.log(email,unitUrl, 'emaikl')
      // const subject              = 'Bolig'
      // const message              = unitUrl
      //       window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`
      // setShare(false)
  }
 
  
  const handleCopyToClipboard = () => { 
    navigator.clipboard.writeText(unitUrl) 
    setShareTooltip(true)
    clearTimeout(clearTooltip)
    clearTooltip= setTimeout(()=> setShareTooltip(false), 3000)
    
  }
  const handleTooltipClose = () => {
   
    clearTimeout(clearTooltip)
    setShareTooltip(false)
    
  }
  const handleSMS = () => {
    const message              = unitUrl
          window.location.href = `sms:?&body=${message}`
  }


  return (
    <form>
 
      <Backdrop onClick = {() => { setShare(false) }} />
      <Container>
      
        <img src = {`${STATICS}generic.png`} alt = '' />
        <Icon {...close} />

        <Inner>
        
          <h2>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'delBoligOverskrift')[0]?.Label }</h2>
          <p>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'delBoligUnderoverskrift')[0]?.Label }</p>
          <div style = {{ height: 20 }} />
          <h3>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'delBoligemailOverskrift')[0]?.Label }</h3>
          <Input pattern='/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/ '  type      = 'email' name="email"  placeholder = 'email@email.dk' onChange = {inputChange}></Input>
          <div    style     = {{ height: 20 }} />
          <Stack  direction = 'row' spacing      = '1px' justifyContent      = 'flex-start'>
         
          <Button  variant   = 'contained' size   = 'large' onClick= {handleDelBolig}  >{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'delBolig')[0]?.Label }</Button>
         
          </Stack>
          <div style = {{height:40}}/>
          { !KioskMode && <div style={{display:'flex',gap:20}}>
            <FacebookShareButton          url  = {unitUrl}><Icon icon = 'facebook' color  = '#000' size = '30'/></FacebookShareButton>
            <FacebookMessengerShareButton url  = {unitUrl}><Icon icon = 'messenger' color = '#000' size = '30'/></FacebookMessengerShareButton>
            <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                PopperProps={{
                  disablePortal: true,
                  sx: { 
                    'div': {
                      padding: '5px 10px',
                      backgroundColor:"black"
                    },
                    'span': {
                      color:"black"
                    }
                  }
                }}
                arrow 
                //disablePadding
                onClose={handleTooltipClose}
                open={shareTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Linket af kopieret til udklipsholder"
              >
                <Button sx={{ minWidth: "unset", padding: "0" }} onClick={handleCopyToClipboard}> <Icon icon='link' color='#000' size='30'/></Button>
              </Tooltip>
            </ClickAwayListener>
            {/* <Icon                         icon = 'link' color         = '#000' size       = '30' click  = {handleCopyToClipboard}/> */}
            {isMobile && <Icon icon='sms' color='#000' size='30' click={handleSMS}/> }
          </div>}
          <div style = {{height:40}}/>
          
        </Inner>
       
      </Container>
      
    
    </form>
  )

}

