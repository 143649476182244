import { vis_compare, vis_movein_price, vis_benefit_extended } from "state/store.global";
import { vis_share } from "state/store.global";
import { vis_calculate_benefit } from "state/store.global";
import { useAtom } from "state/jotai";
import { ComparePortal } from "components/Compare/Compare.Portal";
import { SharePortal } from "components/Share/Share.Portal";
import { BenefitPortal } from "components/HousingBenefit/HousingBenefit";
import { MoveInPricePortal } from "components/HousingBenefit/moveInPrice";
import { HousingBenefitExtended } from "components/HousingBenefit/HousingBenefitExtended";

export const Portals = () => {
  const [comp, setComp]   = useAtom(vis_compare);
  const [share, setShare] = useAtom(vis_share);
  const [bene, setBene]   = useAtom(vis_calculate_benefit);
  const [mP, setMP]       = useAtom(vis_movein_price);
  const [beneExtended, setBeneExtended]       = useAtom(vis_benefit_extended);
  return (
    <>
      {comp && <ComparePortal close={() => setComp(false)} />}
      {share && <SharePortal close={() => setShare(false)} />}
      {bene && <BenefitPortal close={() => setBene(false)} />}
      {mP && <MoveInPricePortal close={() => setMP(false)} />}
      {beneExtended && <HousingBenefitExtended close={() => setBeneExtended(false)} />}
    </>
  );
};
