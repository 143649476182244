import styled     from 'styled-components/macro'
import { BREAK_ } from 'constants'
import { MENU }   from 'constants'

export const Aside = styled.aside`
  background: ${({clr}) => clr?.primary };
  ${ BREAK_.md_dn } {
    position: fixed;
    height  : 100%;
    width   : 100%;
    z-index : 15;
  };
  ${ BREAK_.md_up } {
    z-index : 1;
    height  : 100%;
    width   : ${({w}) => w}px;
    position: fixed;
    top     : 0;
    left    : ${({left,nav}) => left ? 0+MENU.DESKTOP_NAV_W+(nav?300:0)+'px' : 'unset'};
    right   : ${({right}) => right ? 0 : 'unset'};
    ${ ({clr,left})  => left  && `border-left:2px solid ${ clr?.icons}40` };
    ${ ({clr,right}) => right && `border-left:2px solid ${ clr?.primary }` };
  };
  display       : flex;
  flex-direction: column;
  align-items   : center;
  overflow      : auto;
    /* isolation:isolate; */
`