import styled, { css } from 'styled-components/macro'
import { _map } from 'state/store.map'
import { getFocus, setFocus } from 'state/jotai'

// import { AnimatePresence, motion } from 'framer-motion'

export const Svg = ({active,click,svgs}) => {
  
  const hover    = getFocus(_map, 'hover')
  const setHover = setFocus(_map, 'hover')
  
  return (
    <>
    {/* <AnimatePresence> */}
      <svg style={{width:'100%',height:'100%', position:'absolute'}}>
        
        { svgs[active].map( ({disabled, name, d}) => (
          <Path
            // as = {motion.path}
            // initial={{opacity:1,filter:'blur(20px)'}}
            // animate={{opacity:1,filter:'blur(0px)'}}
            // exit={{opacity:1,filter:'blur(20px)'}}
            // transition={{ duration:0.1 }}

            onMouseEnter = {(e)=>{ setHover( e.target.dataset.id ) }}
            onMouseLeave = {(_)=>{ setHover( {} ) }}
            hover        = {hover===name}
            onClick      = {click}
            disabled     = {disabled}
            d            = {d}
            data-id      = {name}
            key          = {d}
          />
        )) }
        
      </svg>
      {/* </AnimatePresence> */}
    </>
  )
}

const Path = styled.path(
  ({ hover, disabled }) => css`
    cursor: pointer;
    stroke: #000080;
    stroke-width: 3px;
    transition: fill 0.5s;
    fill: ${(() => {
      if (!disabled) return hover ? '#ffffff80' : '#00ff0080'
      if (disabled) return hover ? '#00000080' : '#00000080'
    })()};
    :hover {
      fill: ${!disabled ? '#ffffff80' : '#00000080'};
      cursor: ${!disabled ? 'pointer' : 'unset'};
    }
  `
)