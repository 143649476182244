import { drawer_animation_done } from 'state/store.global'
import { _drawer }               from 'state/store.global'
import { vis_desktop_nav }       from 'state/store.global'
import { useAtom }               from 'state/jotai'
import { AnimatePresence }       from 'framer-motion'
import { motion }                from 'framer-motion'
import { useBreak }              from 'hooks/useBreak'
import { Aside }                 from './Drawer.style'
import { Filter }                from 'components/Filter/Filter'
// import { Detail }                from 'components/Detail/Detail'
import { UnitDrawer }            from 'components/Unit'

export const Drawer = ({ filter, sorter, detail, w }) => {

  const drawer   = useAtom(_drawer)[0]  
  const isFilter = drawer.filter && filter
  const isDetail = drawer.detail && detail
  
  return (
    <AnimatePresence>
      { isFilter && <AnimatedDrawer w={w} left><Filter/></AnimatedDrawer> }
      { isDetail && <AnimatedDrawer w={w} right><UnitDrawer /></AnimatedDrawer> }
    </AnimatePresence>
  )
}

const AnimatedDrawer = ({left, right, children, w}) => {

  const setState = useAtom(drawer_animation_done)[1]
  const nav      = useAtom(vis_desktop_nav)[0]
  const isMobile = useBreak('md_dn')
  
  const y_out = isMobile ? '100%' : 0 
  const y_in  = isMobile ? 0 : 0
  
  const x_out = isMobile ? 0 : left ? - (nav?300:0) - w + 'px' : w + 'px' 
  const x_in  = isMobile ? 0 : 0

  const aside = {
    initial   : { x:x_out , y:y_out },
    animate   : { x:x_in  , y:y_in  },
    exit      : { x:x_out , y:y_out },
    transition: { duration:0.3, ease:'linear' }
  }

  return ( 
    <Aside 
      {...aside}
      layout
      clr                 = {clr}
      as                  = {motion.aside}
      left                = {left?.toString()}
      right               = {right?.toString()}
      w                   = {w}
      nav                 = {nav}
      onAnimationStart    = {()=>setState(false)}
      onAnimationComplete = {()=>setState(true)}
    >
      { children }
    </Aside> 
  )
}