import styled from 'styled-components'

export const Col = styled.div`
  align-self     : stretch;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;
  margin         : 0;
  color          : ${({ clr }) => clr?.icons};
  padding        : 10px 0px;
    /* border:1px solid black; */
`
export const Row = styled.div`
  align-self     : stretch;
  display        : flex;
  justify-content: center;
  padding        : 0;
  gap            : 5px;
  font-size      : 0.875rem;
`
export const Button = styled.button`
  border          : 0;
  background-color: ${({isSelected,clr}) => isSelected ? `${clr}ff` : `${clr}1a` };
  color           : ${({isSelected,clr}) => isSelected ? '#000000' : '#ffffff'};
  cursor          : pointer;
  padding         : 10px 20px;
  font-size       : 0.875rem;
  margin          : 5px 0 5px 0;
`