import { useLayoutEffect, useState } from 'react'

export const useScalePoint = (bounds,dimensions,mode,additionalScalepoints=0) => {

  const [res, setRes] = useState(0)

  useLayoutEffect(()=>{

    if ( bounds.width ) {

      const R_map    = ( dimensions.W / dimensions.H  ).toFixed(4)
      const R_bounds = ( bounds.width / bounds.height ).toFixed(4)

      const operator = mode ? ( R_bounds < R_map ) : ( R_bounds > R_map )

      const scaleFactor = ( operator )
          ? bounds.width  / dimensions.W
          : bounds.height / dimensions.H

      setRes( scaleFactor )
    }

  },[bounds,dimensions,mode])

  if (res) return res
}