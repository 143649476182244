import { List } from 'components/List/List'
import { DualMenu } from 'components/DualMenu/DualMenu'
import { TopMenu } from 'components/TopMenu/TopMenu'

export const ViewList = () => {
  return (
    <>
      {/* <TopMenu /> */}
      <List />
      {/* <DualMenu /> */}
    </>
  )
}
