import { Container,  Img } from './MaximizedGallery.Modal.style'
import { Swiper, SwiperSlide } from 'swiper/react'

export const GalleryPortal = ({images, children, initial}) => {

  return (
    <Container>
      { children }
      <Swiper slidesPerView={1} spaceBetween={10} initialSlide={initial ?? 0}>
        { images.map((i, j) => (
            <SwiperSlide key={j}>
              <Img src={i.url} alt='' />
            </SwiperSlide>
          ))}
      </Swiper>
    </Container>
  )
}