import { Arrows }   from './Isometry.Controls.Arrows' 
import { useBreak } from 'hooks/useBreak'

export const Controls = (props) => {

  const isMobile = useBreak('md_dn')

  return (
    <>
      {
        props.frames.length > 1 ? <>
        <Arrows {...props}/>
        </> : <>
          <div style={{border:'0px solid blue', flex:`0 0 ${ isMobile ? 140 : 80 }px`}}/>
        </>
      }      
    </>
  )
  
}