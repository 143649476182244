import { useEffect, forwardRef } from 'react'
import styled from 'styled-components/macro'

export const Canvas = forwardRef(({dim,active,ready,views},ref) => {

  useEffect(()=>{
    
    const ctx = ref?.current?.getContext('2d')
    const w = dim.W
    const h = dim.H
    
    if ( ctx ) {
      ctx.canvas.width = w
      ctx.canvas.height = h
      ctx.clearRect(0,0,w,h)
      // ctx.fillStyle = '#000'
      // ctx.fillRect(0,0,w,h)
      ctx.drawImage(views[active],0,0,w,h)
    }
    
  },[active,ready])


  return (
    <>
      <StyledCanvas w={dim.W} h={dim.H} ref={ref}/>
    </>
  )

})

const StyledCanvas = styled.canvas`
  width: ${x => x.w}px;
  height: ${x => x.h}px;
  max-width: unset;
  background:transparent;
`