import styled from "styled-components/macro";
import { sb } from "styles/styles.scrollbar";
import { BREAK_ } from "constants";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
  prefix: "",
  suffix: " kr.",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const MyStyledInput = styled.input`
  width: 350px;
  height: 44px;
  background: #f1f0f0;
  border: 1px solid #cfd5d2;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding: 0 15px;
`;

export const CurrencyInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return (
    <MaskedInput mask={currencyMask} {...inputProps} className="styledInput" />
  );
};

export const Container = styled.div`
  max-width: 100vw;
  width: 700px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  user-select: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  height: auto;
`;
export const Inner = styled.div`
  padding: 20px 60px 30px 40px;
  h2 {
    font-size: 18px;
    margin: 10;
  }
  p {
    font-size: 17px;
    line-height: 24px;
  }

  ${BREAK_.md_dn} {
    padding: 20px !important;
  }
`;

export const Result = styled.div`
  height: 44px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: #1c6347;
`;

export const SubText = styled.div`
  font-size: 12px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 50px;
`;
