import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import iconSet from "./iconset.json";

function Icon({ icon, ...props }) {
  return (
    <SvgIcon {...props}>
      <path d={iconSet[icon]} />
    </SvgIcon>
  );
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default Icon;
