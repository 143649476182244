import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { ButtonCompare, ButtonFavorite, ButtonShare } from ".";
import { useGet } from "state/jotai";
import { kioskMode } from "state/store.global";
function Buttons({ unitId }) {
  const KioskMode = useGet(kioskMode);

  return (
    <Stack
      direction="row"
      justifyContent="space-around"
      sx={{ bgcolor: "#f3f3f3", py: { md: 1, xs: 0.5 } }}
    >
      {!KioskMode && <ButtonShare unitId={unitId} />}
      {/* <ButtonShare unitId={unitId} /> */}
      <ButtonCompare unitId={unitId} />
      <ButtonFavorite unitId={unitId} />
    </Stack>
  );
}

Buttons.propTypes = {
  unitId: PropTypes.number.isRequired,
};

export default Buttons;
