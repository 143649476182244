import { Isometry }    from 'components/Isometry/Isometry'
import { IsometrySub } from 'components/Isometry/Subisometry'
import { useParams }   from 'react-router-dom'

export const ViewIsometry = () => {

  const sub = useParams('sub').sub

  return (
    <>
      <div style={{border:'0px solid gray',height:'100%',position:'relative'}}>
        { !sub && <Isometry/> }
        { sub  && <IsometrySub sub={sub}/> }
      </div>
    </>
  )
}