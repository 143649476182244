import { useState }              from 'react'
import { useLocation }           from 'react-router-dom'
import { useMousetrap }          from 'use-mousetrap'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { _bootstrap}             from 'state/store.bootstrap'
import { useAtom }               from 'state/jotai'
import { Arrow }                 from './Dropdown.Arrow'
import { variants }              from './Dropdown.Animation'
import { Foldout }               from './Dropdown.Foldout'
import { Face, Box, Outline, Name, Front, Row } from './Dropdown.style'

export const Dropdown = ({mode}) => {

  const { dropdown : { options } } = useAtom(_bootstrap)[0]
  
  const [state, setState] = useState(false)
  
  const location = useLocation().pathname.split('/')[1]
  const outside  = { onTriggered: () => setState(false) }
  const onClick  = () => setState(!state)
  const ref      = useDetectClickOutside(outside)
  
  useMousetrap('d', onClick)

  // console.log( options )

  const face = options.find( i => i.SlugEnd === location )

  const props = { onClick, ref, state }

  return (
    <Row blur={mode ? 0 : 1 }>
      <Box>
        <Outline {...props}>
          <Front>
            <Name>Vælg ejendom:</Name>
            <Face>{ face?.Presentation }</Face>
            <Arrow fill={ mode ? '#000' : '#fff' } dir={ state ? 'up' : 'down' }/>
          </Front>
          <Foldout state={state}/>
        </Outline>
      </Box>
    </Row>
  )
}