import { fn3 }                        from 'utilities/utility.misc'
import { remove, replace }            from 'utilities/utility.filter'
import { applyfilters }                from 'utilities/utility.filter'
import { active_project }             from 'state/store.global'
import { _project }                   from 'state/store.projects'
import { useGet, setFocus, getFocus } from 'state/jotai'

export const useAfter = ({active,setFilters,Default,FilterFor}) => {
  
  const { pin, pid }       = useGet(active_project)
  const { Units, ...rest } = getFocus(_project, 'unitsraw')?.[pin]?.[pid] ?? 'err'
  const setUnits           = setFocus(_project, 'units')
  
  return e => {

    const property = FilterFor
    const min      = e[0]
    const max      = e[1]
    const res      = { property, min, max }

    const comp1    = e
    const comp2    = [Default[0], Default[1]]
    const isActive = JSON.stringify(comp2) !== JSON.stringify(comp1)

    const act      = active.interval
    const interval = isActive ? replace(res,act) : remove(res,act)
    const data     = {...active , interval }
    
    const units_ = applyfilters( Units, data )
    const units  = { ...rest, Units:units_ }
    
    setFilters( a => fn3(a,pin,pid,data) )
    setUnits  ( a => fn3(a,pin,pid,units) )
    
      // clog( 'after.jsx', units_?.length, data.interval[0] )

      // const action  = 'filter_range_changed'
      // const addData = {filterName:property, filterValues:[min,max]}
      // const logData = {filterName:property, filterValues:[min,max]}
      // log({ action, addData, logData })
  }
}
