import styled from 'styled-components/macro'
import { useState } from 'react'

export const Thumb = (props, state) => {

  const fill = clr.buttons

  return (
    <StyledThumb {...props}>
      <SVG></SVG>
    </StyledThumb>
  )
}

const SVG = ({children}) => {

  const [cursor, setCursor] = useState('grab')

  return (
    <Svg 
      viewBox={`0 0 100 100`}
      onMouseDown={()=>setCursor('grabbing')}
      onMouseUp={()=>setCursor('grab')}
      onMouseLeave={()=>setCursor('grab')}
      onMouseEnter={()=>setCursor('grab')}
      style={{cursor:cursor}}
      >
      { children }
    </Svg>
  )
}


const Svg = styled.div`
  width:30px;
  height:30px;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  background:#2A303F;
  border-radius:50%;
  /* z-index:0; */
`

const StyledThumb = styled.div`
  position:relative;
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  /* background-color: #000; */
  color: #fff;
  border-radius: 50%;
`