import styled, {css} from 'styled-components'

export const Button = ({children,click,mode=1,fontSize='1.25rem',fontWeight=600,bgClr, border,color}) => {
  return (
    <StyledButton onClick = {click} mode = {mode} fontSize = {fontSize} fontWeight = {fontWeight} bgClr = {bgClr} border = {border} color = {color}>
      { children }
    </StyledButton>
  )
}

const StyledButton = styled.button(
  ({ mode,fontSize,fontWeight }) => css`
      // background-color: ${mode ? clr?.buttons : 'transparent'};
      // color: ${ mode ? '#000' : clr?.buttons };
    background-color: ${({bgClr}) =>bgClr};
    border          : ${({border}) => `1px solid ${border}`};
    color           : ${({color}) => color };
    padding         : 10px 20px;
    cursor          : pointer;
      // border: ${mode ? '1px solid black' : `1px solid ${clr?.buttons}`};
    text-align : center;
    font-weight: ${fontWeight};
    font-size  : ${fontSize};
    width      : fit-content;
    white-space: nowrap;
  `
)