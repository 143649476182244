import Unit from "components/Unit/Unit.model";
import { get, useGet } from "state/jotai";
import { favorites } from "state/store.global";
import { _project } from "state/store.projects";

const useFavoriteUnits = () => {
  const raw = get(_project, "unitsraw", "Units");
  const favs = useGet(favorites);

  const units = raw.reduce((filtered, unit) => {
    if (favs.includes(unit.UnitID)) {
      filtered.push(new Unit(unit));
    }
    return filtered;
  }, []);

  // returns the array of instance of Unit model.
  return { units };
};

export { useFavoriteUnits };
