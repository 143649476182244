import { useState, useEffect }      from 'react'
import { _project }                 from 'state/store.projects'
import { get, getFocus, useGet }                      from 'state/jotai'
import { Legend, Dot, Status, Txt } from './ColorLegend.style'
import { motion }                   from 'framer-motion'
import { useBreak }                 from 'hooks/useBreak'
import { active_project } from 'state/store.global'
import { Box } from '@mui/material'

export const ColorLegend = () => {

  const [comb, setComb] = useState()

  const raw = get(_project, 'unitsraw', 'Units') 
  const { pin, pid } = useGet(active_project) 
  const options      = getFocus(_project, 'filteroptions')?.[pin]?.[pid] ?? []
  const unitStatus = options.filter( o => o.Type === "MultiSelect" && o.Label === "Status")[0]?.PossibleValues

  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  useEffect(()=>{
    if ( raw.length ) {
      setComb([...new Set( raw.map(i => {
        return `${i.Data.UnitStatusName.Value}___${i.Data.UnitColor.Value.toLowerCase()}`
      }))])
    }
  },[raw])
  const isDesktop = useBreak('md_up')

  const animation = {
    initial   : { transform: 'translate(-50%, 300px)'},
    animate   : { transform: 'translate(-50%, 0)'},
    exit      : { transform: 'translate(-50%, 300px)'},
    transition: { duration:0.2, ease:'linear' }
  }


  if (raw.length && comb) return (
    <>
      <Legend {...animation}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.7rem'}}>
        <Box sx={{ fontWeight: '600' }}>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'StatusOverskrift')[0]?.Label }</Box>
        {
          comb.map(i => {
            const name = i.split('___')[0]
            const color = i.split('___')[1]
            return (
              <Status key={i}>
                <Dot style={{background:color}}/>
                <Txt children={name}/>
              </Status>
            )
          })
        }
        </Box>
      </Legend>
    </>
  )

}