const components = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        height: "100%",
        fontSize: 16,
        overflow: "hidden",
        overscrollBehavior: "none",
        webkitOverflowScrolling: "touch",
      },
      body: {
        height: "100%",
        overflow: "hidden",
        overscrollBehavior: "none",
        webkitOverflowScrolling: "touch",
        color: "#000000",
      },
      img: {
        height: "auto",
        maxWidth: "100%",
      },
      p: {
        margin: 0,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: "none",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: 14,
        fontWeight: 700,
        textTransform: "none",
        borderRadius: 0,
        boxShadow: "none",
        "&.Mui-disabled": {
          cursor: "not-allowed",
          pointerEvents: "auto",
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
          opacity: 0.7,
        },
      }),
      sizeLarge: {
        fontSize: 16,
      },
      textSizeLarge: {},
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: "currentColor",
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        // fontSize: 24,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        },
      },
    },
    defaultProps: {
      PaperProps: {
        elevation: 3,
      },
    },
  },
};

export default components;
