import { forwardRef } from 'react'
import styled         from 'styled-components/macro'
// import Logo           from 'assets/estatetool_logo_opacity2.svg'

export const Cursor = forwardRef( ( { setCursor, children }, measure ) => 
  <CursorWrap 
    ref         = { measure } 
    onMouseDown = { () => setCursor('grabbing') } 
    onMouseUp   = { () => setCursor('grab') }>
    { children }
  </CursorWrap>
)

const CursorWrap = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  /* background-image: url(${Logo}); */
  /* background-position: top left; */
  /* background-size: 50px 50px; */
  /* background-clip: border-box; */
  /* background-color: #000; */
`