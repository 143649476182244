import { Label, Main } from "./Isometry.Controls.Arrows.style";
import { useEffect, useState } from "react";
import { animate } from "framer-motion";
import { Arrow } from "./Isometry.Controls.Arrows.assets";
import { useBreak } from "hooks/useBreak";
import { motion } from "framer-motion";
import { active_project, kioskMode } from "state/store.global";
import { getFocus, useGet } from "state/jotai";
import { _project } from "state/store.projects";
import useMousetrap from "use-mousetrap";
import { getIsoData } from 'state/jotai.isometryDataHooks'

export const Arrows = ({ frame, setFrame, config, index, setIndex }) => {
  const [state, setState] = useState(0);
  const isMobile = useBreak("md_dn");
  const SNP = config.IMAGE_SNAPFRAMES;
  const MAX = SNP?.length - 1;
  const MIN = 0;

  const getKioskMode = useGet(kioskMode);
  const currentLocation = window.location.href;
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const percent = getIsoData('percent')

  const click = DIR => {
    if (state == 1) return;
    setState(1);
    const from = (() => {
      if (index == MAX && DIR > 0) return SNP?.[MIN];
      if (index == MIN && DIR < 0) return SNP?.[MAX];
      return SNP?.[index];
    })();
    const to = (() => {
      if (index == MAX && DIR > 0) return SNP?.[1];
      if (index == MIN && DIR < 0) return SNP?.[MAX - 1];
      return SNP?.[index + DIR];
    })();
    const toIndex = (() => {
      if (index == MAX && DIR > 0) {
        return 1;
      }
      if (index == MIN && DIR < 0) {
        return MAX - 1;
      }
      return index + DIR;
    })();
    setIndex(toIndex);
    animate(from, to, {
      duration: 0.3,
      transition: { ease: "linear" },
      onUpdate: v => setFrame(+v.toFixed(0)),
      onComplete: () => setState(0),
    });
  };
  //console.log("Current index:", index);
  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5, ease: "linear" },
  };

  // Set up Mousetrap key bindings for left and right arrows
  Mousetrap.bind("right", () => click(+1));
  Mousetrap.bind("left", () => click(-1));

  useEffect(() => {
    // Clean up Mousetrap bindings when the component unmounts
    return () => {
      Mousetrap.unbind("right");
      Mousetrap.unbind("left");
    };
  }, []);
  // useMousetrap("left", () => click(-1));
  // useMousetrap("right", () => click(+1));
  return (
    <Main as={motion.div} {...animation}>
      <Arrow
        D={"L"}
        w={isMobile ? "30" : "36"}
        click={() => click(-1)}
        fill={clr.primary_text_passive}
        {...{percent}}
      />
      { percent == 100 && <Label>
        {
          buttonsLabel?.filter(btn => btn.UILabelProperty === "SkiftVinkel")[0]
            ?.Label
        }
      </Label>}
      <Arrow
        D={"R"}
        w={isMobile ? "30" : "36"}
        click={() => click(+1)}
        fill={clr.primary_text_passive}
        {...{percent}}
      />
    </Main>
  );
};
