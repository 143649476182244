export const animation = {
  initial    : { height:0       },
  animate    : { height:'unset' },
  exit       : { height:0       },
  transition : { ease:'linear' , duration:0.1 } 
}
export const variants = {
  open       : { },
  closed     : { },
  // transition : { ease:'linear' }
}