import { get, uget, rent }                         from 'components/Favorites/Favorites.Utilities'
import { getFocus, get as projectGet, useGet }     from '../../../state/jotai'
import { _project }                                from '../../../state/store.projects'
import { active_project }                          from 'state/store.global'

const j = `${'\u00A0'} • ${'\u00A0'}`

export const data = {
  A : [
    {key:'1',joiner:j,properties:['Projeknr']},
      // {key:'1',joiner:j,properties:['Boligtype']},
    {key:'2',joiner:j,properties:['SalesTypeName']},
    {key:'3',joiner:j,properties:['Adresse']},
    {key:'4',joiner:j,properties:['Pris']},
    {key:'5',joiner:j,properties:['Areal','Vaer','Uderum','Aspects']},
  ],
  B : [
    {key:'1',joiner:j,properties:['SalesTypeName']},
    {key:'2',joiner:j,properties:['Adresse']},
    {key:'3',joiner:j,properties:['Pris']},
    {key:'4',joiner:j,properties:['Areal','Vaer','Uderum','Aspects']},
  ],
  C : [
    {key:'1',joiner:j,properties:['Projeknr']},
    {key:'2',joiner:j,properties:['SalesTypeName']},
    {key:'3',joiner:j,properties:['Adresse']},
    {key:'4',joiner:j,properties:['Areal','Vaer','Uderum','Aspects']},
    {key:'5',joiner:j,properties:['Pris']},
  ]
}

export const form = (unit,line) => line.properties
  .filter(m => {
    if ( m == 'Projeknr' ) return get(unit,'Projeknr')            ? true: false
    if ( m == 'Areal'    ) return get(unit,'Areal')               ? true: false
    if ( m == 'Vaer'     ) return get(unit,'VaerelserAntal')      ? true: false
    if ( m == 'Uderum'   ) return get(unit,'Uderum')              ? true: false
    if ( m == 'Aspects'  ) return get(unit,'FremhaevedeAspekter') ? true: false
    if ( m == 'Boligtype') return get(unit,'Boligtype')           ? true: false
    return true
  })
  .map(m => {
    if ( m == 'Projeknr'      ) return projeknr (unit,m)
    if ( m == 'Boligtype'     ) return boligtype(unit,m)
    if ( m == 'Pris'          ) return pris     (unit,m)
    if ( m == 'SalesTypeName' ) return saletype (unit,m)
    if ( m == 'Adresse'       ) return adresse  (unit,m)
    if ( m == 'Areal'         ) return fremhaev (unit,m)
    if ( m == 'Vaer'          ) return fremhaev (unit,m)
    if ( m == 'Uderum'        ) return fremhaev (unit,m)
    if ( m == 'Aspects'       ) return fremhaev (unit,m)
    return 'err'
  })
  .join( line.joiner )

const projeknr = (i,x) => {
  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  return `${ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Bolignr')[0]?.Label } ${ get(i,x) }`
}

const boligtype = (i,x) => {
  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  return `${ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Boligtype')[0]?.Label }: ${ get(i,x) }`
}

const pris     = (i) => {
  const { pin, pid } = useGet(active_project)
  const unitStatus   = getFocus(_project, 'unitStatuses')?.[pin]?.[pid] ?? []
 // const Pris = i.getPris();
    // console.log(unitStatus, 'unitStatus')
    // const a = '/md. i husleje'
    // const b = ' kr.'
    const availableUnits = i?.Data?.UnitStatusID?.Value === 10;
  const availablePris = i?.Data?.Pris_Tekst_DK?.Value === "";
  const c     = rent(i) ? 'Husleje' : 'Kontantpris'
  const d = rent(i) ? 'Pris' : ""
  //console.log("🚀 ~ file: Card.data.jsx:81 ~ pris ~ d:", d)
  const price = get(i,d) + ' ' + getPostfix(i, c) + ' ' + get(i,'LejelejlighedsText').toLowerCase();
    // const d = typeof uget(i,c) == 'string'
    // const e = get(i,c) + b + (rent(i) ? a : '')
  
 
  //const f = get(i,'UnitStatusName')?.toUpperCase() != unitStatus.filter( s => s.ID === 10)[0]?.Name?.toUpperCase()
  const g =  availablePris ? price  : "" ; /* checking if available unit and available price condition is true then show the price value else show empty*/
  return g 
}

const saletype = (i,x) => get(i,x)

const adresse = (i,x) => `${get(i,x)}, ${get(i, "ZipcodeID")} ${get(i, "By")}`

const getPostfix = (unit = "", property = "Husleje") => {
  return unit?.Data?.[property]?.Postfix ?? "";
}

const fremhaev = (i,x) => {

  let text = projectGet(_project, 'cardtext').flatMap((data) => {
    return data.filter(p => p.Property === "VaerelserAntal");
  })[0]?.Postfix

  if ( x == 'Areal'   ) return `${ get(i,x) } m²`
    // if ( x == 'Vaer'    ) return `${ get(i,'VaerelserAntal') } vær.`
  if ( x == 'Vaer'    ) return `${ get(i,'VaerelserAntal') } ${text}`
  if ( x == 'Uderum'  ) return `${ get(i,x) }`
  if ( x == 'Aspects' ) return `${ get(i,'FremhaevedeAspekter').split(',').join(j) }`
  
}