import { useEffect, useState } from "react";
import { Backdrop } from "styles/styles.backdrop";
import { getFocus, useAtom, useGet } from "state/jotai";
import {
  Box,
  InputBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import { STATICS } from "api/api";
import { Icon } from "assets/Icons_";
import styled from "styled-components";
import {
  active_project,
  vis_calculate_benefit,
  vis_benefit_extended,
} from "state/store.global";
import { housing_benefit } from "state/store.global";
import { gross_income } from "state/store.global";
import {
  Container,
  Inner,
  Result,
  SubText,
  CurrencyInput,
} from "./HousingBenefit.style";
import "./HousingBenefit.css";
import { useHousingBenefit } from "./HousingBenefit.hook";
import { _project } from "state/store.projects";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { CustomLabel } from "components/Contact/styled-components";
import CircularProgress from '@mui/material/CircularProgress';
import { useActiveUnit } from "components/Unit";


export const HousingBenefitExtended = () => {
  const unit = useActiveUnit();
  const price = unit.getPrice();
  const area = unit.getArea();
  const rooms = unit.getNoOfRooms();
  const[loading, setLoading]=useState(false);
  const[lComplete, setLComplete]=useState(false);
  const [beneExtended, setBeneExtended] = useAtom(vis_benefit_extended);
  const [pensioner, setPensioner] = useState(false);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const output = useHousingBenefit();
  const href  = 'https://www.boligstoette.dk/'
  const link  = <a href = {href} style={{color:"#00000050"}}>https://www.boligstoette.dk/</a>

  const icon = {
    icon: "close6",
    color: "#fff",
    wrap: { position: "absolute", top: 20, right: 20 },
    size: "20",
    click: () => setBeneExtended(false),
  };
  const openPensioner = () => {
    setPensioner(!pensioner)
  }
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLComplete(true);
      }, 1000);
    }
  }, [loading]);

  return (
    <>
      <Backdrop
        onClick={() => {
          setBeneExtended(false);
        }}
      />
      <Container>
        <Box sx={{ position: "relative" }}>
          <img src={`${STATICS}generic.png`} alt="" />
          <Box
            sx={{
              position: "absolute",
              bottom: "25px",
              left: "41px",
              color: "white",
              width:"215px",
            }}
          >
            {!loading && <H2>Beregn din boligstøtte for bolig 34D</H2>}
          </Box>
        </Box>
        <Icon {...icon} />
        {!loading && !lComplete && <Box sx={{padding:"20px 70px 25px 35px", height:"510px", overflow:{xs:'scroll'}}}>
          <H2> Boliginfo </H2>
          <div>
            <Grid container spacing={2} sx={{marginTop:"0px"}}>
              <Grid item xs={6} md={2}>
                <FormControl sx={{ mb: 3 }} fullWidth variant="filled">
                  <CustomLabel shrink>Antal værelser</CustomLabel>
                  <CustomInput value={rooms} disabled/>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl sx={{ mb: 3 }} fullWidth variant="filled">
                  <CustomLabel shrink>Antal m2</CustomLabel>
                  <CustomInput value={area} disabled/>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl sx={{ mb: 3 }} fullWidth variant="filled">
                  <CustomLabel shrink>Husleje pr. md. </CustomLabel>
                  <CustomInput value={`${price}/md.`} disabled/>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={2}>
                <FormControl sx={{ mb: 3 }} fullWidth variant="filled">
                  <CustomLabel shrink>Antal voksne</CustomLabel>
                  <CustomInput value={"2"}/>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl sx={{ mb: 3 }} fullWidth variant="filled">
                  <CustomLabel shrink>Antal børn under 18 år</CustomLabel>
                  <CustomInput value={"2"}/>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl sx={{ mb: 3 }} fullWidth variant="filled">
                  <CustomLabel shrink>
                    Hustandens samlede indkomst pr. md. (før skat)
                  </CustomLabel>
                  <CustomInput value={"84.351 kr./md."}/>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <Box  sx={{height:{md:'170px',xs:'auto'}}}>
            <div>
              <H2>Er du pensionist?</H2>
              <FormGroup sx={{ flexDirection: "row" }}>
                <CustomFormControlLabel
                  control={<Checkbox/>}
                  label="Ja"
                  onClick={openPensioner}
                />
                <CustomFormControlLabel
                  control={<Checkbox/>}
                  label="Nej"
                />
              </FormGroup>
            </div>
            { pensioner &&
              <div >
                <H2>Hvilken type pensionist?</H2>
                <FormGroup sx={{ flexDirection: "row" }}>
                  <CustomFormControlLabel
                    control={<Checkbox/>}
                    label="Folkepensionist"
                  />
                  <CustomFormControlLabel
                    control={<Checkbox/>}
                    label="Førtidspensionist (før 2003)"
                  />
                  <CustomFormControlLabel
                    control={<Checkbox/>}
                    label="Førtidspensionist (efter 2003)"
                  />
                </FormGroup>
              </div>
            }
            </Box>
            <Box sx={{display:"flex", justifyContent:"center"}}>
              <Button
                variant="contained"
                endIcon={<ArrowRightAltIcon />}
                sx={{
                  borderRadius: "7px",
                  border: "1px solid #9CFAA5",
                  backgroundColor: "#9CFAA5",
                  color: "#084A4A",
                  "&:hover": {backgroundColor: "#9CFAA5" }
                }}
                onClick={() => setLoading(true)}
              >
                BEREGN BOLIGSTØTTE
              </Button>
            </Box>
          <Stack direction="row" spacing="1px" justifyContent="center"></Stack>
        </Box>}
        {loading && !lComplete && <Box>
            <Box sx={{ display: 'flex', flexDirection:"column",justifyContent:'center',alignItems:'center',height:'510px' }}>
              <CircularProgress sx={{color:'grey'}}/>
              <p>Venligst vent mens vi <br/> beregner din boligstøtte</p>
            </Box>
          </Box>}
          {
            lComplete  && <Box sx={{padding:"50px 70px 55px 35px", height:'510px'}}>
              <Box sx={{paddingBottom:"15px"}}>
                <H2>Din vejledende boligstøtte</H2>
              </Box>
              <Box sx={{display:"flex",flexDirection:"column",padding:"15px 0"}}>
                <H2 style={{color:"#1C6347"}}>1.279 kr.</H2>
              </Box>
              <Box sx={{ height:"280px"}}>
                <p style={{color:"#00000080", fontSize:"12px", lineHeight:"16px"}}>Den boligstøtte beregning du kan se her, er vejledende og vi giver ingen garanti i forhold til den 
                   endelige boligstøtte du måtte kunne få. Brug eventuelt {link} som har en beregner der stilles til rådighed af Udbetaling Danmark.</p>
              </Box>
              <Box sx={{display:"flex", justifyContent:"center"}}>
              <Button
                variant="contained"
                endIcon={<ArrowRightAltIcon />}
                sx={{
                  borderRadius: "7px",
                  border: "1px solid #9CFAA5",
                  backgroundColor: "#9CFAA5",
                  color: "#084A4A",
                  "&:hover": {backgroundColor: "#9CFAA5" }
                }}
                onClick={() => setBeneExtended(false)}
              >
                GEM BOLIGSTØTTE
              </Button>
            </Box>
            </Box>
          }
      </Container>
    </>
  );
};

export const H2 = styled.h2`
  font-size:18px;
  font-weight:700;
`;
export const Line = styled.div`
  min-height: 1px;
  max-height: 1px;
  width: 100%;
  background: lightgray;
`;
// export const CustomGrid = styled(Grid)
export const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiTypography-root': {
    color: '#00000080',
  },
  '& .MuiCheckbox-root.Mui-checked + .MuiTypography-root': {
    color: '#000000',
  },
}));

const CustomInput = styled(InputBase)(() => ({
  // "label + &": {
  //   marginTop: theme.spacing(1),
  // },

  "& .MuiInputBase-input": {
    textAlign      : "right",
    borderRadius   : 0,
    position       : "relative",
    backgroundColor: "#ffffff10",
    border         : "1px solid transparent",
    borderColor    : "#00000055",
    fontSize       : 16,
    width          : "100%",
    padding        : "10px",
    margin         : "5px 0",
    transition     : "border-color 0.3s",
    "&:focus"      : {
      borderColor: "#000000",
    },
  },
  // "&.Mui-error .MuiInputBase-input": {
  //   borderColor: theme.palette.error.main,
  // },
}));