import styled from 'styled-components/macro'
import Switch from 'react-switch'

export const DiffSwitch = ({ showDiff, setShowDiff }) => {

  return (
    <>
      <SwitchLabel>
        <Switch
          onChange      = {() => setShowDiff(a => !a)}
          checked       = {showDiff}
          uncheckedIcon = {false}
          checkedIcon   = {false}
          height        = {20}
          width         = {48}
          onColor       = {'#cec'}
          offColor      = {'#eee'}
          // handleDiameter={}
        />
        Vis kun forskelle
      </SwitchLabel>
    </>
  )

}

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  line-height: 2;
  gap: 5px;
  font-size: 1.0625rem;
  font-weight: 400;
`