import { useAtom } from "jotai";
import { open_drawer, close_drawer } from "state/actions";
import { active_unit } from "state/store.global";

const useUnitDrawer = () => {
  const [, openDrawer] = useAtom(open_drawer);
  const [, closeDrawer] = useAtom(close_drawer);
  const [, setActiveUnit] = useAtom(active_unit);

  const open = (unit) => {
    openDrawer("detail");
    setActiveUnit(unit.data);
  };

  const close = () => {
    closeDrawer("detail");
  };

  return { open, close };
};

export default useUnitDrawer;
