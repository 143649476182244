import { useEffect } from 'react'
import { useState }  from 'react'
import { animate }   from 'framer-motion'

export const Play = ({frame, setFrame, config }) => {

  const min = config.FIRST_IMAGE
  const max = config.LAST_IMAGE
  const num = config.IMAGE_NUM


  const [state, setState]     = useState()
  // const [playing, setPlaying] = useState(true)

  const [from, setFrom] = useState(frame)
  const [to, setTo]     = useState(max+(frame-min))
  
  useEffect(()=>{
    const controls = animate(from, to, {
      duration   : 10,
      ease       : 'linear',
      repeat     : Infinity,
      onUpdate   : v => {
        const val = Number(v.toFixed(0))
        const to = ( val > max ) ? val - num + 1 : val 
        setFrame(to)
      } 
    })
    return () => {
      controls.stop()
    }
  },[from,to])

  return null
}