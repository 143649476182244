import { useEffect }        from 'react'
import { useFetch }         from 'hooks/fetch/useFetch'
import { setFocus, useGet } from 'state/jotai'
import { _subiso }          from 'state/store.subiso'
import { renderLayout }     from 'state/store.global'
import { preloadImage }     from 'utilities/utility.images'
import { ISO_CONFIG }       from 'api/api'
import eachOfLimit          from 'async/eachOfLimit'
import { fn4 }              from 'utilities/utility.misc'

export const PreloadFrames_TinySubs = () => {
  const file     = 'PreloadingTinySub.jsx'
  const url      = ISO_CONFIG
  const config   = useFetch({ url, file }).D
  const ready    = useGet(renderLayout)
  const setTiny  = setFocus(_subiso, 'tiny')
  const setFrame = setFocus(_subiso, 'activeframe')
  const setMode  = setFocus(_subiso, 'mode')
  useEffect(()=>{
    if ( config && ready ) {
      config.forEach((i,j) => {
        const pin  = j
        const pid  = config[j].PROJECT_ID
        const subs = config[j].SUBS
        subs.forEach( (k,l) => {
          eachOfLimit([k], 1, 
            ( val, _, callback ) => {
              const IMG = preloadImage(val.TINY)
              const INI = val.FIRST_IMAGE
              setTiny ( a => fn4(a,pin,pid,val.NAME, {'NAME':val.NAME, 'RES':IMG } ) )
              setFrame( a => fn4(a,pin,pid,val.NAME, {'NAME':val.NAME, 'RES':INI } ) )
              setMode ( a => fn4(a,pin,pid,val.NAME, {'NAME':val.NAME, 'RES':true } ) )
              IMG.onload = () => callback()
            },
            () => {
              // console.log( 'Done preloading tiny frames', k.NAME )
            }
          ) 
        })
      })
    }
  },[config, ready])
  return null
}