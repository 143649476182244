import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import {
  active_project,
  vis_calculate_benefit,
  vis_movein_price,
  vis_benefit_extended,
} from "state/store.global";
import { getFocus, useAtom, useGet } from "state/jotai";
import { useHousingBenefit } from "components/HousingBenefit/HousingBenefit.hook";
import { _project } from "state/store.projects";
import { Icon } from "assets/Icons_";

function Properties({ groups, indent }) {
  const setBenefit = useAtom(vis_calculate_benefit)[1];
  const output = useHousingBenefit();
  const setMovePrice = useAtom(vis_movein_price)[1];
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  
  return (
    <>
      {groups &&
        groups.map((group, i) => {
          return (
            <Box
              key={i}
              sx={{
                mb: 5,
                "&:last-child": {
                  mb: "0",
                },
              }}
            >
              <Box sx={{ fontWeight: 700, mb: "10px" }}>{group.title}</Box>
              <div>
                {group.data &&
                
                  group.data.map((data, i) => {
                    //console.log(data)
                    const label = data.Label;
                    const value =
                      data.Value === true
                        ? buttonsLabel?.filter(
                            btn => btn?.UILabelProperty === "Ja"
                          )[0]?.Label
                        : data.Value === false
                        ? buttonsLabel?.filter(
                            btn => btn?.UILabelProperty === "Nej"
                          )[0]?.Label
                        : data.Value;
                    const postfix = data.Postfix ?? "";

                    {
                      /* if ( data.PropertyID !== 113 ) return ( */
                    }
                    return (
                      <Box
                        key={i}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          px: indent ? 3 : 0,
                          py: 1.5,
                          borderBottom: "1px solid rgba(112, 112, 112, 0.1)",
                          "&:last-child": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <Box sx={{ display: "flex", gap: "2px" }}>
                          {label}
                          
                          {label === "Indflytningsdato" ? (
                            data.PropertyID == 103 ? (
                              <Box
                                onClick={() => setMovePrice(true)}
                                sx={{
                                  color: "unset",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                <Icon icon="info" color="#000" size="11" />
                              </Box>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </Box>
                        {data.PropertyID !== 113 ? (
                          <div>
                            {value}
                            {postfix}
                          </div>
                        ) : (
                          <>
                            {output ? (
                              <div
                                onClick={() => setBenefit(true)}
                                style={{
                                  color: "unset",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                {output}
                              </div>
                            ) : (
                              <div
                                onClick={() => setBenefit(true)}
                                style={{
                                  color: "unset",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                {
                                  buttonsLabel?.filter(
                                    btn => btn.UILabelProperty === "Beregn"
                                  )[0]?.Label
                                }
                              </div>
                            )}
                          </>
                        )}
                      </Box>
                    );
                  })}
              </div>
            </Box>
          );
                  console.log("🚀 ~ file: Properties.jsx:136 ~ Properties ~ data:", data)
                  console.log("🚀 ~ file: Properties.jsx:134 ~ Properties ~ data:", data)
                  console.log("🚀 ~ file: Properties.jsx:133 ~ Properties ~ data:", data)
                  console.log("🚀 ~ file: Properties.jsx:133 ~ Properties ~ data:", data)
                  console.log("🚀 ~ file: Properties.jsx:133 ~ Properties ~ data:", data)
        })}
    </>
  );
}

Properties.propTypes = {
  groups: PropTypes.array.isRequired,
  indent: PropTypes.bool,
};

export default Properties;
