import styled from 'styled-components/macro'
import { BREAK } from 'constants'

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* border:5px solid red; */
`
export const Main = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 167px;
  border-left: 8px solid ${({ color }) => color};
  /* padding-right: 10px; */
  /* padding-left: 8px; */
  border-radius: 7px 10px 10px 7px;
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
  /* ${ BREAK.md_dn('height:167px') } */
  background-color: ${({ color }) => (() => color + '22')()};
  :hover {
    background-color: ${({ color }) => (() => color + '33')()};
  }
`