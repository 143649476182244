import { css } from 'styled-components/macro'
export const FONT = { FAMILY:'Arial', FALLBACK:'', COLOR:'#000'}
export const MENU = { 
  MOBILE_NAV_H           : 60,
  DESKTOP_NAV_W          : 100,
  DESKTOP_NAV_DRAWER_W   : 300,
  DESKTOP_UNIT_DRAWER_W  : 422,
  DESKTOP_FILTER_DRAWER_W: 431,
}

export const SIDEBAR   = import.meta.env.VITE_SIDEBAR          === 'true'
export const KIOSK     = import.meta.env.VITE_KIOSK_MODE       === 'true'
export const WP        = import.meta.env.VITE_WORDPRESS_PARENT === 'true'
export const FRONTPAGE = import.meta.env.VITE_FONTPAGE         === 'true'
export const IFRAMED   = import.meta.env.VITE_IFRAMED          === 'true'

export const DEVICE_ID_KEY      = 'deviceId'
export const SESSION_ID_KEY     = 'sessionId'
export const SESSION_EXPIRY_KEY = 'sessionExpiry'

export const ACTION = {
  ThumbClick  : 'clicked_image_on_unit_details_thumb_menu',
  Swipe       : 'swiped_to_new_image_on_unit_details_gallery_slider',
  ThumbSwipe  : 'swiped_to_new_image_on_unit_details_thumbnail_slider',
  ToFullScreen: 'opened_image_on_unit_details_menu_to_fullscreen_or_modal',
}

export const PX = {
  unitpage  : 1500,
  card      : 501,
  md_dn     : 1023,   //767,
  md_up     : 1024,   //768,
  md_form_dn: 900,    //900,
}

export const BR = {
  unitpage    : { minWidth: PX.unitpage },
  card        : { minWidth: PX.card  },
  md_dn       : { maxWidth: PX.md_dn },
  md_up       : { minWidth: PX.md_up },
  form_md_down: { minWidth: PX.md_form_dn }
}

export const BREAK = {
  unitpage: x =>
    css` @media (min-width: ${PX.unitpage}px) {
      ${x} } `,
  card: x =>
    css` @media (min-width: ${PX.card}px) {
      ${x} } `,
  md_dn: x =>
    css` @media (max-width: ${PX.md_dn}px) { 
      ${x} } `,
  md_up: x =>
    css` @media (min-width: ${PX.md_up}px) { 
      ${x} } `,
}

export const BREAK_ = {
  unitpage: `@media (min-width: ${PX.unitpage}px)`,
  card    : `@media (min-width: ${PX.card}px)`,
  md_dn   : `@media (max-width: ${PX.md_dn}px)`,
  md_up   : `@media (min-width: ${PX.md_up}px)`,
}