const RES = "/munkesohuse/";
const API = "https://eidodata.munkesoehuse.dk/v1/";
  // const API = "https://60a40935-06f7-4c32-96a4-431d1171cf0a.estatetool.net/v1/"
const BUN       = "https://eidoisometri.b-cdn.net/";
const EMAIL_API = "send/mail"

export const FLOORPLAN = `${BUN}defaults/plantegning.jpg`;

export const ISO_CONFIG = `${RES}data/iso` + ".json";
export const MAP_CONFIG = `${RES}data/map` + ".json";

export const COLORS     = `${RES}data/colors` + ".json";
export const CONTACT    = `${RES}data/contact` + ".json";
export const NAVLINKS   = `${RES}data/nav` + ".json";
export const HIGHLIGHTS = `${RES}data/highlights` + ".json";
export const MAP_SVGS   = `${RES}data/map_svg` + ".json";
export const ISO_SVGS   = `${RES}data/iso_svg` + ".json";

export const ISO_FRAMES = `${BUN}`;
export const MAP_FRAMES = `${RES}map`;
export const STATICS    = `${RES}logos/`;

export const BOOT = `${API}bootstrap`;
export const IP   = `${API}getip`;

const BF1 = "/housingbenefits?GrossIncome=";
const BF2 = "&SiteID=";

export const PROJECT = (PID, LNG = "da") =>
  PID ? `${API}projects/${PID}?Language=${LNG}`: null;
export const PRJ   = PID => (PID ? `${API}projects/${PID}` : null);
export const UNITS = (PID, LNG = "da") =>
  PID ? `${PRJ(PID)}/units?Language=${LNG}`: null;
export const VERSION = PID => (PID ? `${PRJ(PID)}/unitsdataversion` : null);
export const LOG     = PID => (PID ? `${PRJ(PID)}/logs` : null);
export const BENEFIT = (PID, ID, INC, SITE) =>
  PID ? `${PRJ(PID)}/units/${ID}${BF1}${INC}${BF2}${SITE}`: null;
export const UNIT = (PID, ID, LNG = "da") =>
  PID ? `${PRJ(PID)}/units/${ID}?Language=${LNG}`: null;
export const COMPARE = (PID, LIST_OF_IDS, LNG = "da") =>
  PID
    ? `${PRJ(PID)}/unitscompare?UnitIDs=${LIST_OF_IDS}&Language=${LNG}`
    :    null;
export const PAGE = (PID, PAGEID, LNG = "da") =>
  PID ? `${PRJ(PID)}/pages/${PAGEID}?Language=${LNG}`: null;
export const DOWNLOADS = (PID, LNG = "da") =>
  PID ? `${PRJ(PID)}/downloads?Language=${LNG}`: null;

export const MOVEINCALCULATIONS = (PID , ID )=> PID ? `${PRJ(PID)}/units/${ID}/moveincalculations` : null
export const EMAIL              = () =>  `${API}${EMAIL_API}`
