import React from "react";
import { useState } from "react";

const options = [
  {
    id: "da",
    label: "Dansk",
    icon: (
      <>
        <path fill="#be003a" d="M24 4.999h-24v14.002h24v-14.002z"></path>
        <path fill="#fff" d="M10.384 4.999h-2.596v14.002h2.596v-14.002z"></path>
        <path fill="#fff" d="M24 10.698h-24v2.596h24v-2.596z"></path>
      </>
    ),
  },
  {
    id: "en",
    label: "English",
    icon: (
      <>
        <path fill="#fff" d="M24 4.865h-24v14.27h24v-14.27z"></path>
        <path
          fill="#bd0034"
          d="M16.069 10.025l7.931-4.526v-0.634h-0.543l-9.046 5.16h1.659z"
        ></path>
        <path
          fill="#bd0034"
          d="M15.697 14.259l8.303 4.737v-0.947l-6.644-3.79h-1.659z"
        ></path>
        <path
          fill="#bd0034"
          d="M0 5.951l7.101 4.074h1.666l-8.768-5.022v0.947z"
        ></path>
        <path
          fill="#bd0034"
          d="M8.389 14.259l-8.389 4.788v0.087h1.516l8.532-4.876h-1.659z"
        ></path>
        <path fill="#1a237b" d="M22.362 4.865h-8.41v4.796l8.41-4.796z"></path>
        <path
          fill="#1a237b"
          d="M10.248 4.865h-8.374l8.374 4.796v-4.796z"
        ></path>
        <path fill="#1a237b" d="M24 10.025v-3.272l-5.7 3.272h5.7z"></path>
        <path fill="#1a237b" d="M24 17.495v-3.236h-5.7l5.7 3.236z"></path>
        <path
          fill="#1a237b"
          d="M2.353 19.135h7.895v-4.511l-7.895 4.511z"
        ></path>
        <path
          fill="#1a237b"
          d="M13.952 19.135h7.924l-7.924-4.511v4.511z"
        ></path>
        <path fill="#1a237b" d="M0 14.259v3.389l5.9-3.389h-5.9z"></path>
        <path fill="#1a237b" d="M0 10.025h5.9l-5.9-3.389v3.389z"></path>
        <path
          fill="#bd0034"
          d="M10.984 4.865v6.005h-10.984v2.544h10.984v5.721h2.231v-5.721h10.784v-2.544h-10.784v-6.005h-2.231z"
        ></path>
      </>
    ),
  },
]

const useLanguage = () => {
  // TODO: dynamic lang.
  const [activeLang, setActiveLang] = useState("en");
  return [activeLang, setActiveLang, options];
}

export default useLanguage;
