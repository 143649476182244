import { Box } from "@mui/material";
import React from "react";
import {
  Buttons,
  DrawerCTA,
  Gallery,
  Highlights,
  Price,
  TopDrawer,
  useActiveUnit,
} from ".";
import { useUnitHighlight } from "./hooks";
import OpenHouse from "./OpenHouse";
import { getFocus, useAtom, useGet } from "state/jotai";
import { get } from "components/Favorites/Favorites.Utilities";
import { drawer_animation_done } from "state/store.global";
import { PoweredBy } from "components/PoweredBy/PoweredBy";

function UnitDrawer() {
  const unit = useActiveUnit();
  //console.log("🚀 ~ file: UnitDrawer.jsx:20 ~ UnitDrawer ~ unit:", unit)
  const highlights = useUnitHighlight(unit);
  const unitId = parseInt(unit.getId());
  const status = unit.getStatus();
  const address = unit.getAddress();
  const townAndZipcode = unit.getTownZipCode();
  const images = unit.getImages();
  const isRent = unit.isRent();
  const price = unit.getPrice();
  const Pris = unit.getPris();
  const availableUnits = unit?.data?.Data?.UnitStatusID?.Value === 10;
  const availablePris = unit?.data?.Data?.Pris_Tekst_DK?.Value === "";

  const isDrawerAnimating = useAtom(drawer_animation_done)[0];

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        // pb: 5,
        backgroundColor: "#ffffff",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between"

      }}
    >
      <Box>
      <TopDrawer
        address={`${address}, ${townAndZipcode}`}
        statusText={status.label}
        backgroundColor={status.color}
        textColor="#ffffff"
      />

      {isDrawerAnimating && <Gallery images={images} UnitDrawer />}

      <Buttons unitId={unitId} />

      <OpenHouse />

      <Box sx={{ px: 2.5, mt: { md: 3.5, xs: 2 } }}>
        <Box sx={{ mb: { md: 3.5, xs: 2 }, mx: "auto", maxWidth: 350 }}>
          <Highlights items={highlights} />
        </Box>

        <Box
          sx={{
            mb: { md: 5.5, xs: 2 },
            mt: { md: 5, xs: 2 },
            textAlign: "center",
          }}
        >
          <Price
            label={
              availablePris && isRent ? `${get(unit.data, "LejelejlighedsText")}` : ""
            }
            value={availablePris && price ? Pris : ""}
          />
        </Box>

        <DrawerCTA unit={unit} />
      </Box>
      </Box>
      <PoweredBy />
    </Box>
  );
}

export default UnitDrawer;
