import { useAtom }     from 'state/jotai'
import { _fullscreen } from 'state/store.global'
import { Icon }        from 'assets/Icons_'
import { useBreak }    from 'hooks/useBreak'

export const Exit = () => {
  
  const [, setIsFullscr] = useAtom(_fullscreen)
  const isDesktop = useBreak('md_up')

  return <span
    onClick = {()=>setIsFullscr(false)}
    style   = {{
      position      : 'absolute',
      top           : isDesktop ? 0 : 0,
      right         : isDesktop ? 60: 5,
      cursor        : 'pointer',
      zIndex        : 10,
      width         : 60,
      height        : 60,
      background    : '#000000F2',
      display       : 'flex',
      justifyContent: 'center',
      alignItems    : 'center',
    }}>
      <Icon icon={'close1'} size={25} text={'LUK'} fontSize={'1rem'}/>
    </span>

}