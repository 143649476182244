import { useBreak }   from 'hooks/useBreak'
import { NavMobile }  from './Nav.Mobile'
import { NavDesktop } from './Nav.Desktop'

export const Nav = () => {
  
  return useBreak('md_dn') 
    ? (
      <NavMobile/>
    ) : ( 
      <NavDesktop/>
    )
    
}