import { _map }               from 'state/store.map'
import { getFocus, setFocus } from 'state/jotai'
import { Label, Name, Sub }   from './Map.Labels.style'

import { useBreak } from 'hooks/useBreak'

const check = (x) => isFinite(1/x) && !isNaN(1/x)

export const Labels = ({ scale, active }) => {

  const isDesktop = useBreak('md_up')

  const data     = getFocus(_map, 'config')?.[active]?.LABELS
  const hover    = getFocus(_map, 'hover')
  const setHover = setFocus(_map, 'hover')
  const s        = check(scale) ? 1/scale : 1 || 1
  const lab      = {padding:`${10*s}px ${60*s}px`,borderRadius:`${0*s}px`}
  const line     = (DISABLED) => ({stroke:DISABLED?'#000':'#fff', strokeWidth:2*s})

  if ( isDesktop ) return (
    <>
      <svg style={{width:'100%',height:'100%',position:'absolute',pointerEvents:'none'}}>
        { data?.map(({MAIN,C1,C2,DISABLED})=>(
          <g key={MAIN}>
            <line x1={C1[0]} y1={C1[1]} x2={C2[0]} y2={C2[1]} {...line(DISABLED)}/>
            <circle cx={C1[0]} cy={C1[1]} r={3*s} fill={DISABLED?'#00000080':'#ffffff80'}/>
            <circle cx={C2[0]} cy={C2[1]} r={3*s} fill={DISABLED?'#00000080':'#ffffff80'}/>
          </g>
        )) }
      </svg>
      { data?.map(({MAIN,C2,SUB1,SUB2,ORIGIN,DISABLED})=>(
        <span key={MAIN}>
          <Label 
            origin={ORIGIN} 
            key={MAIN} 
            x={C2[0]} 
            y={C2[1]} 
            style={lab}
            data-id={MAIN}
            onMouseEnter={(e)=>{ setHover( e.target.dataset.id ) }}
            onMouseLeave={(_)=>{ setHover( {} ) }}
            hover={hover===MAIN}
            disabled={DISABLED}
          >
            <Name s={s}>{MAIN}</Name>
            <Sub  s={s}>{SUB1}</Sub>
            <Sub  s={s}>{SUB2}</Sub>
          </Label>
        </span>
      )) }
    </>
  )

  return null

}

