import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Hotkeys } from "hotkeys";
import { useSetActiveProject } from "hooks/useSetActiveProject";
import { useSearchParams } from "react-router-dom";
import { setProjectData, getProjectData } from "state/jotai.projectDataHooks";

export const Root = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const rooms = searchParams.get("rooms");
  const status = searchParams.get("status");
  const getActiveFilters = getProjectData("activefilters");
  const setActiveFilters = setProjectData("activefilters");

  const data = (() => {
    const statusParam = status === "ledig";
    const roomsParam = rooms ? true : false;

    const interval = roomsParam
      ? [{ property: "VaerelserAntal", min: rooms, max: rooms }]
      : [];
    const multiSelect = status
      ? [
          {
            property: "Status",
            values: [status === "ledig" && "UnitStatusLedig"],
          },
        ]
      : [];

    return { interval, multiSelect };
  })();

  const ready = useSetActiveProject();

  useEffect(() => {
    if (ready) {
      setActiveFilters(data);
    }
    // console.log( 'root' , rooms, status, '\n', getActiveFilters )
  }, [ready]);

  return (
    <>
      <Hotkeys />{" "}
      {/* Hotkeys are added here so that they can access the react router API */}
      <Outlet /> {/* Outlet is where all Views are rendered */}
    </>
  );
};
