import { useLayoutEffect }      from 'react'
import { useScalePoint }  from 'hooks/useScalePoint' 
import { _map }           from 'state/store.map'

export const useMap = (bounds,cursor,ref,dimensions,setScale,mode) => {

  const scale = useScalePoint(bounds,dimensions,mode)

  useLayoutEffect(() => {

    ref.current?.zoomToElement('center', scale, 0, null)
    setScale(scale ? scale : 0 )

  },[scale,bounds,mode])
  
  const wrapperProps = {
    wheel              : { step: 0.04 },
    limitToBounds      : true,
    panning            : { velocityDisabled: true },
    alignmentAnimation : { sizeX: 0, sizeY: 0 },
    zoomAnimation      : { size: 0 },
    minScale           : scale ?? 0,
    maxScale           : scale ? 8 : 0,
    onInit             : event => event.zoomToElement('center',scale,0,null),
    onZoom             : event => setScale( event.state.scale ),
  }
  const wrapperStyle = {
    width  : '100%',
    height : '100%',
    cursor : cursor,
  }
  const contentStyle = {
    width  : dimensions.W,
    height : dimensions.H,
  }
  return {
    wrapperProps,
    wrapperStyle,
    contentStyle,
  }
}