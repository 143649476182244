import { Figure, Svg, Group, Line, Text, Circle, Label } from "./Compas.style";
import { motion } from "framer-motion";
import { iso_free } from "state/store.global";
import { useAtom } from "jotai";

export const Compas = ({frame,cfg,label, percent}) => {
  
  const [rmode] = useAtom(iso_free)

  const init = cfg?.SLIDER_COMPASS?.[0] ?? 0;
  const max = cfg?.LAST_IMAGE;
  const min = cfg?.FIRST_IMAGE;
  //const init = 691;
  // const max = cfg?.LAST_IMAGE;
  // const min = cfg?.FIRST_IMAGE;

  const inc = frame - min;
  //  console.log("🚀 ~ file: Compas.jsx:14 ~ Compas ~ frame:", frame)

  const degr = init + 360 * (inc / (max - min));
  // let degr = 691;
  // console.log(degr, index);
  // if (index === 0) {
  //   degr = 691;
  //   console.log(index, "0");
  // } else if (index === 1) {
  //   degr = 500;
  //   console.log(index, "1");
  // } else if (index === 2) {
  //   degr = 500;
  //   console.log(index, "2");
  // } else if (index === 3) {
  //   degr = 691;
  //   console.log(index, "3");
  // } else if (index === 4) {
  //   degr = 691;
  //   console.log(index, "4");
  // }

  //  if (frame === 1000) {
  //    degr = 691;
  //    console.log(frame, "0");
  //  } else if (frame <= 1070) {
  //    degr = 500;
  //    console.log(index, "1");
  //  } else if (frame <= 1140) {
  //    degr = 500;
  //    console.log(frame, "2");
  //  } else if (frame <= 1180) {
  //    degr = 691;
  //    console.log(frame, "3");
  //  } else if (frame <= 1250) {
  //    degr = 691;
  //    console.log(frame, "4");
  //  }
  // if (rmode === true) {
  //   degr = init + 360 * (inc / (max - min));
  // }

  const move = (cx = "", cy = "", dir = "-") => ({
    transform: `rotate(${dir}${degr} ${cx} ${cy})`,
  });

  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5, ease: "linear" },
  };

  if ( !isNaN(degr) ) return (
    percent == 100 &&
    <>
      <Figure rmode={rmode} as={motion.div} {...animation}>
        <svg viewBox='0 0 1000 1000'>
          <g {...move(500, 500, '+')}>
            <path d='M471.5 101a400.4 400.4 0 0 0-254.3 116 400 400 0 1 0 565.6 0 400.4 400.4 0 0 0-311.3-116zm26.8 152.5 149.8 448.3L500 553.7 352 701.8l146.3-448.3z' />
          </g>
        </svg>
        <Label>
          { label }
        </Label>
      </Figure>
    </>
  )
  return null

}
