import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { active_project, kioskMode } from "state/store.global";
import { _bootstrap } from "state/store.bootstrap";
import { _project } from "state/store.projects";
import { getFocus, useSet } from "state/jotai";

export const useSetActiveProject = () => {
  const currentLocation = window.location.href;
  const [res, setRes] = useState(false);

  const type = getFocus(_bootstrap, "type");
  const location = useLocation().pathname;
  const names = getFocus(_bootstrap, "projectnames");
  const ids = getFocus(_bootstrap, "projectids");

  const setActiveID = useSet(active_project);
  const setKioskMode = useSet(kioskMode);
  useEffect(() => {
    if (location) {
      if (currentLocation.includes("kiosk"))
        setKioskMode(true);
      const loc = type == "poly" ? location.split("/")[1] : null;
      const fnc = type == "poly" ? x => x.URLSafe === loc : null;
      const obj = type == "poly" ? names.filter(fnc)?.[0] : null;
      const pid = type == "poly" ? obj?.ProjectID || 0 : ids[0];
      const pin = type == "poly" ? names.indexOf(obj) : 0;
      setActiveID({ pid, pin });
      setRes(true);
    }
  }, [location]);

  return res;
};
