import styled from 'styled-components/macro'
import { BREAK } from 'constants'

export const Img = styled.img`width: 100%;`

export const Card = styled.div`
  display: flex;
  width: 100%;
  // padding: 8px;
  padding: 8px 0;
  font-size: 0.8125rem;
  flex-direction: column;
  gap:10px;
  /* height:200px; */
  position:relative;
`

export const Line = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  :nth-child(1) { font-weight: 600; }
  border:4px solid orange;
`

export const Favor = styled.div`
  font-weight: bold;
  line-height: 0.6875rem;
  border:4px solid orange;
`

export const CTA = styled.div`
  display:flex;
  line-height:1;
  gap:5px;
  align-items:center;
  /* border:1px solid black; */
`

export const Low = styled.div`
  display: flex;
  gap: 30px;
  font-size:1rem;
  justify-content: flex-start;
  padding: 0 15px;
  ${ BREAK.md_dn('justify-content: space-between')};
  font-weight: 600;
  height: 25px;
`

export const Separator = styled.div`
  width: 96%;
  min-height: 0.5px;
  margin-left: auto;
  display: block;
  ${ BREAK.md_dn('width: calc(100% - 8%)')};
  ${ BREAK.md_dn('margin: auto')};
  ${ BREAK.md_dn('justify-content: space-between')};
  background: ${({ color }) => `${color}22`};
`

export const Status = styled.div`
  font-weight: bold;
  color: ${({ color }) => color};
  line-height: 0.6875rem;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 1rem;
  /* ${ BREAK.md_nd('top:5px' ) }; */
  /* ${ BREAK.md_nd('right:5px' ) }; */
`