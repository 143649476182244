import { useState, useRef }   from 'react'
import { _map }               from 'state/store.map'
import { getFocus, setFocus } from 'state/jotai'
import { ZoomPanPinch }       from 'components/ZoomPanPinch/ZoomPanPinch'
import { Dropdown }           from 'components/Dropdown/Dropdown'
import { useMap }             from './Map.Hook'
import { useClick }           from './Map.Click'
import { Cursor }             from './Map.Cursor'
import { Center }             from './Map.Center'
import { Nav }                from './Map.Nav'
import { Canvas }             from './Map.Canvas'
import { Svg }                from './Map.Svg'
import { Fit }                from './Map.Fit'
import { Labels }             from './Map.Labels'
import useMeasure             from 'react-use-measure'

export const Map = () => {

  const [measure, bounds] = useMeasure()
  
  const views    = getFocus(_map, 'views')
  const config   = getFocus(_map, 'config')
  const init     = getFocus(_map, 'initial')
  const scale    = getFocus(_map, 'activescale')
  const ready    = getFocus(_map, 'ready')
  const svgs     = getFocus(_map, 'svgs')

  const setScale = setFocus(_map, 'activescale')
  
  const [mode  , setMode  ] = useState(false)
  const [cursor, setCursor] = useState('grab')
  const [active, setActive] = useState(init)
  
  const max    = views.length-1
  const zppRef = useRef()
  const canRef = useRef()
  const dim    = config[active].DIMENSIONS
  const point  = config[active].CENTER
  const hook   = useMap(bounds,cursor,zppRef,dim,setScale,mode)
  const click  = useClick()

  return <>
    <Cursor         {...{ ref:measure, setCursor }} >
      <Dropdown     {...{ mode }} /> 
      <Fit          {...{ setMode , mode }} />
      {/* <Nav          {...{ setActive, max, active }} /> */}
      <ZoomPanPinch {...{ ref:zppRef, ...hook }} >
        <Canvas     {...{ ref:canRef, active, ready, dim, views }} />
        <Svg        {...{ active, click, svgs }} />
        <Center     {...{ point }} />
        <Labels     {...{ scale, active, key:active }} />
      </ZoomPanPinch>
    </Cursor>
  </>
}