import { useRef, useEffect } from 'react'

import useMeasure                       from 'react-use-measure'
import { _project }                     from 'state/store.projects'
import { _subiso }                      from 'state/store.subiso'
import { active_subiso }                from 'state/store.subiso'
import { active_project }               from 'state/store.global'
import { iso_free }                     from 'state/store.global'
import { iso_isrotating }               from 'state/store.global'
import { setFocus, useGet, useSet }     from 'state/jotai'
import { getSubisoData, setSubisoData } from 'state/jotai.isometryDataHooks'
import { getProjectData }               from 'state/jotai.projectDataHooks'

import { Cursor }            from './Isometry.Cursor'
import { createZppSetup }    from './Isometry.ZoomPanPinch'
import { Canvas }            from './Isometry.Canvas'
import { Center }            from './Isometry.Center'
import { Tiny }              from './Isometry.Tiny'
import { Controls }          from './Isometry.Controls'
import { Measure }           from './Isometry.Measure'
import { Space }             from './Isometry.style'
import { Svg }               from './Subisometry.Svg'
import { Loading2 }          from 'components/Loading2/Loading2'
import { ZoomPanPinch }      from 'components/ZoomPanPinch/ZoomPanPinch'
import { Compas }            from 'components/Compas/Compas'
import { ColorLegend }       from 'components/ColorLegend/ColorLegend'
import { NoSnapSlider }      from 'components/IsometryControls/NoSnapSlider'
import { Play }              from 'components/IsometryRotation/Play'
import { SnapOnRotationEnd } from 'components/IsometrySnapOnRotationEnd/SnapOnRotationEnd'

import { useTranslation }    from 'react-i18next'

export const IsometrySub = ({sub}) => {
  
  const [measure, bounds]   = useMeasure()

  const { pin, pid } = useGet(active_project)
  const setsub = useSet(active_subiso)
  
  const mode = true
  const tiny = getSubisoData('tiny', 'src')
  const frames = getSubisoData('frames')
  const percent = getSubisoData('percent')
  const status = getSubisoData('status')
  const config = getSubisoData('config')
  const frame = getSubisoData('activeframe')
  const svg = getSubisoData('overlays', frame)
  const index = getSubisoData('snapindex')
  
  const units = getProjectData('units', 'Units')

  const setIndex = setSubisoData('snapindex')
  const setFrame = setSubisoData('activeframe')
  const setScale = setFocus(_subiso, 'activescale')
  
  const zppRef = useRef()
  const canRef = useRef()
  const dim = { W: config?.IMAGE_W, H: config?.IMAGE_H }
  const point = config?.CENTER
  const zppsetup = createZppSetup(bounds, zppRef, dim, setScale, mode)
  const rmode = useGet(iso_free)
  const isrotate = useGet(iso_isrotating)
  const { i18n:{language} } = useTranslation()

  useEffect(() => setsub(sub),[pin,pid,sub])
  useEffect(() => {
    if ( pin >= 0 && pid && config && sub ) {
      const snap = config?.IMAGE_SNAPFRAMES
      setIndex(snap?.indexOf(frame))
    }
  },[config])

  if ( config && pid && pin >= 0 ) return (
    <>
      <Cursor>
        <Space/>
        <SnapOnRotationEnd {...{ frame, setFrame, config, setIndex, rmode, isrotate }} />
        <Measure ref={measure}>
          <ZoomPanPinch ref={zppRef} {...zppsetup }>
            <Tiny {...{ tiny, dim, status, percent }}/>
            <Canvas {...{ ref:canRef, frame, pid, status, dim, frames, config}} />
            <Center {...{ point }} />
            { !rmode && <Svg {...{ svg, percent, units }} /> }
          </ZoomPanPinch>
        </Measure>
        <Loading2 {...{ progress:percent, show:(status !== 'DONE' || !units) && frames.length > 1 }} />
        { rmode && <>
          <NoSnapSlider {...{ frame, setFrame, config, frames, index, setIndex }}/>
          { isrotate && <Play {...{ frame, setFrame, config, setIndex }} /> }
        </>}
        { !rmode && <>
          <Compas frame={frame} cfg={config} label='Mod nord'/>
          <ColorLegend/>
          <Controls {...{ frame, setFrame, config, frames, index, setIndex }} />
        </> }
      </Cursor>
    </>
  )
}