import { Grid, Box, Button, Stack } from "@mui/material";
import { Icon }                     from "components/Icons";
import useLanguage                  from "components/LanguageMenu/useLanguage";
import { useOpenHouseData }         from "components/OpenHouseBanner/OpenHouseBanner.Data";
import React                        from "react";
import { useTranslation }           from 'react-i18next'
import { getFocus, useGet }         from "state/jotai";
import { active_project }           from "state/store.global";
import { _project }                 from "state/store.projects";

function OpenHouse() {
  
  const { ready, MM, MMM, Sitename, Time, Description, Address } = useOpenHouseData();
  const { i18n:{language} }                             = useTranslation()

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  return (
    <>
      {ready && (
        <Box   sx        = {{ px: 2.5, my: { xs: 2, md: 4 } }}>
        <Stack direction = "row" sx = {{ py: { xs: 1, md: 0}, border: "1px solid", borderColor: "common.black", lineHeight: 1.3, }} >
            {/* left */}
            <Box sx = {{ py: 2, px: 1.25, borderRight: "1px solid", borderRightColor: "common.black", textAlign: "center", lineHeight: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center', flexGrow: { md: 1} }} >
            <Box sx = {{ fontSize: 35, fontWeight: "bold", mb: "2px", }} >
                {MM}
              </Box>
              <Box sx = {{ fontSize: 16, }} >
                {MMM}
              </Box>
            </Box>
            {/* right */}
            <Box px = {1.5} pt = {1.5} pb = {0.5} sx = {{ flexGrow: { md: 5} }}>
              <strong>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'OpenHouseTitle')[0]?.Label} {Sitename}</strong>
              <div>{Time}</div>
              <div    dangerouslySetInnerHTML = {{ __html:Address}}></div>
              {/* <Button href                    = {`https://terrassehaverne.dk/${language}/locations/terrassehaverne/#open-house`} sx = {{ px: 0, py: 0.5, }} endIcon = {<Icon icon="long-arrow" />} >
                { buttonsLabel?.filter( btn => btn.UILabelProperty === 'LæsMere')[0]?.Label }
              </Button> */}
            </Box>
          </Stack>
        </Box>
      )}
    </>
  );
}

export default OpenHouse;
