import { useEffect }           from 'react'
import { useAtom }             from 'state/jotai'
import { _fullscreen }         from 'state/store.global'
import { FullScreen }          from 'react-full-screen'
import { useFullScreenHandle } from 'react-full-screen'
import { isMobileSafari }      from 'react-device-detect'
import { Portal }              from 'components/Portal/Portal'

export const Fullscreen = ({children}) => {

  const [isFullscr, setIsFullscr] = useAtom(_fullscreen)

  const close  = () => setIsFullscr(false)
  const handle = useFullScreenHandle()
  const change = state => { if (!state) close() }

  const openFullscreen  = isFullscr && !handle.active && !isMobileSafari
  const closeFullscreen = !isFullscr && handle.active

  useEffect(()=>{
    if ( openFullscreen  ) { handle.enter() }
    if ( closeFullscreen ) { handle.exit() }
  },[isFullscr])

  return isMobileSafari ? (
    <Portal id={'FullscreeniOS'}>
      { isFullscr && children }
    </Portal>
  ) : (
    <FullScreen handle={handle} onChange={change}>
      { isFullscr && handle.active && children }
    </FullScreen>
  )

}