import { useEffect }          from 'react'
import { _bootstrap }         from 'state/store.bootstrap'
import { _project }           from 'state/store.projects'
import { active_project }     from 'state/store.global'
import { useFetch }           from 'hooks/fetch/useFetch'
import { useFetch_Units }     from 'hooks/fetch/useFetch_Units'
import { useGet, setFocus }   from 'state/jotai'
import { PROJECT, PAGE }      from 'api/api'
import { fn3 }                from 'utilities/utility.misc'
import { initialsort as isr } from 'utilities/utility.sort'
import { useTranslation }     from 'react-i18next'

export const ProjectData_Poly = () => {

  const { i18n:{language} } = useTranslation()

  const type         = useGet(_bootstrap).type
  const { pin, pid } = useGet(active_project)

  const poly = type === 'poly'
  const file = poly ? 'main/ProjectData.jsx' : ''

  const url1 = pid ? PROJECT(pid) + '.json' : null
  const url2 = pid ? PAGE(pid,1050,language) : null
  const url3 = pid ? PAGE(pid,1080,language) : null

  const project   = useFetch({ url:poly?url1:null, file }).D
  const downloads = useFetch({ url:poly?url2:null, file }).D
  const gallery   = useFetch({ url:poly?url3:null, file }).D

  useFetch_Units({ pid:poly?pid:null, pin:poly?pin:null })

  const setCardtext      = setFocus(_project, 'cardtext')
  const setStatustypes   = setFocus(_project, 'statustypes')
  const setUnittypes     = setFocus(_project, 'unittypes')
  const setSorteroptions = setFocus(_project, 'sorteroptions')
  const setFilteroptions = setFocus(_project, 'filteroptions')
  const setHandpicked    = setFocus(_project, 'handpicked')
  const setDefaultsort   = setFocus(_project, 'activesorting')
  const setGallery       = setFocus(_project, 'gallery')
  const setDownloads     = setFocus(_project, 'downloads')
   
  useEffect(()=>{
    if ( poly && project && downloads && gallery && pin >= 0 ) {
      
      const p = project.config
      
      setCardtext     ( a => fn3(a, pin, pid, p.ShowInListView   ) )
      setStatustypes  ( a => fn3(a, pin, pid, p.UnitStatuses     ) )
      setUnittypes    ( a => fn3(a, pin, pid, p.SaleAndUnitTypes ) )
      setSorteroptions( a => fn3(a, pin, pid, p.SortOrders       ) )
      setFilteroptions( a => fn3(a, pin, pid, p.Filters          ) )
      setHandpicked   ( a => fn3(a, pin, pid, [1,2,3,111,200]    ) )
      setDefaultsort  ( a => fn3(a, pin, pid, isr(p.SortOrders)  ) )
      
    }
  },[poly, project, downloads, gallery])
  
}