import { isMobileSafari } from 'react-device-detect'
import { Exit }           from './MaximizedGallery.Exit'
import { Slider }         from './MaximizedGallery.Slider'
import { GalleryPortal }  from './MaximizedGallery.Modal'


export const MaximizedGallery = ({initial,images}) => {

  return ( isMobileSafari ) ? (
    <GalleryPortal initial={initial} images={images}>
      <Exit/>
    </GalleryPortal>
  ) : (
    <>
      <Exit/>
      <Slider initial={initial} images={images}/>
    </>
  )
}

