import { useEffect, useState } from 'react'
import { Main, Control, Text } from './NoSnapSlider.style'
import { Thumb } from 'components/SliderThumb/SliderThumb'
import { Track } from 'components/SliderTrack/SliderTrack'
import { getFocus, useGet, useSet } from 'state/jotai'
import { active_project, iso_isrotating } from 'state/store.global'
import { vis_overlay } from 'state/store.global'
import { motion } from 'framer-motion'
import { _project } from 'state/store.projects'

export const NoSnapSlider = ({ frame, setFrame, config, index, setIndex }) => {

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3, ease: 'linear' },
  }

  const ini = config.FIRST_IMAGE
  const max = config.FIRST_IMAGE + config.IMAGE_NUM - 1
  const end = config.IMAGE_NUM - 1
  const marks = config.IMAGE_SNAPFRAMES

  const [render, setRender] = useState(false)
  const setOverlay = useSet(vis_overlay)
  const setRotate = useSet(iso_isrotating)
  const change = v => setFrame(v)

  useEffect(() => {
    setTimeout(() => {
      setRender(true)
    }, 2000)
    setOverlay(false)
    return () => {
      setOverlay(true)
    }
  }, [])

  if (!isNaN(end))
    return (
      <Main
        {...animation}
        onTouchStart={() => setRotate(false)}
        onTouchMove={() => setRotate(false)}
        // onMouseDown        = {()=>setRotate(false)}
        // onClick            = {()=>setRotate(false)}
        onMouseDownCapture={() => setRotate(false)}
      >
      
        { render && (
          <motion.div {...animation} style={{width:'100%'}}>
            <Text>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'AdministrerselvModel')[0]?.Label }</Text>
            <Control
              value={frame}
              defaultValue={frame}
              renderTrack={Track}
              renderThumb={Thumb}
              step={1}
              max={max}
              min={ini}
              marks={[...marks]}
              onSliderClick={() => setRotate(false)}
              onChange={change}
            />
          </motion.div>
        )}
      </Main>
    )
}
