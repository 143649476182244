import { useEffect }        from 'react'
import { useFetch }         from 'hooks/fetch/useFetch'
import { setFocus, useGet } from 'state/jotai'
import { _subiso }          from 'state/store.subiso'
import { renderLayout }     from 'state/store.global'
import { ISO_CONFIG }       from 'api/api'
import eachOfLimit          from 'async/eachOfLimit'
import axios                from 'axios'
import { fn4 }              from 'utilities/utility.misc'

export const PreloadSvg_OverlaysSub = () => {

  const file        = 'main/Overlays/Overlays.Sub.jsx'
  const url         = ISO_CONFIG
  const config      = useFetch({ url, file }).D
  const ready       = useGet(renderLayout)
  const setOverlays = setFocus(_subiso, 'overlays')

  useEffect(()=>{

    if ( config && ready ) {
      
      config.forEach((i,j) => {

        const pin  = j
        const pid  = config[j].PROJECT_ID
        const subs = config[j].SUBS

        subs.forEach((k,l) => {

          const path = k.SVG_JSON_PATH
          const arr  = k.IMAGE_SNAPFRAMES
          const col  = arr.map( (i,j) => `${path}${i}.json` )
          const ovr  = {}

          eachOfLimit(col, col.length, 
            ( val, idx, callback ) => {
              axios.get(val).then((res)=>{
                ovr[ arr[idx] ] = res.data
                setOverlays(a => fn4(a,pin,pid,k.NAME,{'NAME':k.NAME,'RES':ovr}))
                callback()
              })
            },
            () => {
              // console.log( 'Done preloading svg overlays for ', pin, pid, k.NAME )
            }
          )
        })
      })
    }
  },[config, ready])

}